import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  TouchableWithoutFeedback,
  useWindowDimensions,
} from 'react-native';
import * as defaultStyles from '../../assets/defaultStyles';
import ArchivoText from './text/ArchivoText';
import CreteRoundText from './text/CreteRoundText';

const Popup = (props) => {
  const screenWidth = useWindowDimensions().width;
  const screenHeight = useWindowDimensions().height;

  const {
    onBlur = () => {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver: true,
      }).start(() => {
        if (props.hidePopup) {
          props.hidePopup();
        }
      });
    },
  } = props;

  const [fadeAnim] = useState(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: true,
    }).start();

    return () => {};
  }, []);

  return Platform.OS === 'web' ? (
    <View style={styles.webContainer}>
      <View
        style={[
          styles.popup,
          { borderRadius: 10, paddingBottom: defaultStyles.padding[4] },
          props.style,
        ]}
      >
        <View style={styles.webTitle}>
          <ArchivoText bold style={styles.popupTitle} primary>
            {props.title}
          </ArchivoText>
          <TouchableOpacity
            onPress={() => {
              if (props.hidePopup) {
                props.hidePopup();
              }
            }}
          >
            <Ionicons
              name='ios-close'
              size={35}
              style={{ horizontalPadding: defaultStyles.padding[3] }}
              color={defaultStyles.primary}
            />
          </TouchableOpacity>
        </View>
        <CreteRoundText style={styles.popupDescription} primary>
          {props.description}
        </CreteRoundText>
        {props.children && (
          <View style={styles.childrenContainer}>{props.children}</View>
        )}
        {props.options && (
          <View style={styles.options}>
            {props.options.map((option, index) => {
              return (
                <TouchableOpacity
                  disabled={option.disabled}
                  key={index}
                  onPress={() => {
                    if (option.action) {
                      option.action();
                    }
                    onBlur();
                  }}
                >
                  <View
                    style={{
                      padding: defaultStyles.padding[2],
                    }}
                  >
                    <CreteRoundText
                      style={{
                        color: option.color || defaultStyles.primary,
                        fontSize: defaultStyles.fontSize[1],
                        opacity: option.disabled ? 0.4 : 1,
                      }}
                    >
                      {option.value}
                    </CreteRoundText>
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
        )}
      </View>
    </View>
  ) : (
    <TouchableWithoutFeedback
      onPress={onBlur}
      style={{
        zIndex: 1,
        elevation: 1,
        width: screenWidth,
        height: screenHeight,
      }}
    >
      <Animated.View
        style={{
          ...styles.container,
          opacity: fadeAnim,
          width: screenWidth,
          height: screenHeight,
        }}
      >
        <Animated.View
          style={{
            ...styles.popup,
            ...props.style,
            transform: [
              {
                translateY: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [300, 0],
                }),
              },
            ],
          }}
        >
          <ArchivoText bold style={styles.popupTitle} primary>
            {props.title}
          </ArchivoText>
          <CreteRoundText style={styles.popupDescription} primary>
            {props.description}
          </CreteRoundText>
          {props.children && (
            <View style={styles.childrenContainer}>{props.children}</View>
          )}
          {props.options && (
            <View style={styles.options}>
              {props.options.map((option, index) => {
                return (
                  <TouchableOpacity
                    disabled={option.disabled}
                    key={index}
                    onPress={() => {
                      if (option.action) {
                        option.action();
                      }
                      onBlur();
                    }}
                    style={[
                      {
                        padding: defaultStyles.padding[2],
                      },
                      option.style,
                    ]}
                  >
                    <View>
                      <CreteRoundText
                        style={{
                          color: option.color || defaultStyles.primary,
                          fontSize: defaultStyles.fontSize[1],
                          opacity: option.disabled ? 0.4 : 1,
                        }}
                      >
                        {option.value}
                      </CreteRoundText>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
        </Animated.View>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  webTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  webContainer: {
    zIndex: 999,
    alignSelf: 'flex-start',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: '-50%' }, { translateY: '-50%' }],
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: defaultStyles.primary50,
  },
  container: {
    zIndex: 999,
    elevation: 999,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: defaultStyles.primary50,
  },
  popup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '90%',
    maxWidth: 600,
    elevation: 3,
    backgroundColor: defaultStyles.white,
    paddingHorizontal: defaultStyles.padding[4],
    paddingTop: defaultStyles.padding[4],
    paddingBottom: defaultStyles.padding[8],
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,

    elevation: 12,
  },
  popupTitle: {
    fontSize: defaultStyles.fontSize[2],
  },
  popupDescription: {
    fontSize: defaultStyles.fontSize[0],
    opacity: 0.7,
    marginVertical: defaultStyles.margin[3],
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
});

export default Popup;
