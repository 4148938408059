import React, { useState, useContext, useEffect } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';

import { SettingsContext } from '../../DefaultContainer';
import * as ds from '../../../assets/defaultStyles';
import RoomRow from './RoomRow';
import Search from './Search';
import CreteRoundText from '../../components/text/CreteRoundText';
import ArchivoText from '../../components/text/ArchivoText';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { LinearGradient } from 'expo-linear-gradient';

function JoinGame({
  rooms,
  onJoinGame,
  onJoinPrivateGame,
  onCreateGame,
  isLoading,
}) {
  const { i18n } = useContext(SettingsContext);
  const { insets } = initialWindowMetrics
    ? initialWindowMetrics
    : { insets: { bottom: 0 } };
  const [search, setSearch] = useState('');
  const query = search.toLowerCase();
  const filteredRooms = rooms.filter((room) => {
    return room.id.includes(query);
  });

  return (
    <ScrollView
      style={styles.roomsContainer}
      stickyHeaderIndices={[0, 2]}
      contentContainerStyle={{
        paddingBottom:
          insets && insets.bottom
            ? insets.bottom + ds.padding[9]
            : ds.padding[13],
        justifyContent: 'center',
      }}
      keyboardDismissMode='on-drag'
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps='handled'
    >
      <LinearGradient
        colors={[ds.almostWhite, ds.almostWhite0]}
        locations={[0.3, 1]}
        style={{ height: 30 }}
        pointerEvents='none'
      />
      <View style={styles.titleContainer}>
        <View style={styles.gameTitleContainer}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <CreteRoundText style={styles.gameTitle} bold primary>
              {i18n.t('games')}
            </CreteRoundText>
          </View>
          <View
            style={[
              styles.roomNumber,
              { backgroundColor: isLoading ? ds.almostWhite0 : ds.primary },
            ]}
          >
            <ArchivoText
              bold
              style={{
                fontSize: ds.fontSize[1],
                color: ds.white,
                textAlign: 'center',
              }}
            >
              {rooms.length}
            </ArchivoText>
          </View>
        </View>
      </View>
      <View style={styles.searchContainer}>
        <Search search={search} setSearch={setSearch} />
      </View>

      {filteredRooms.length !== 0 ? (
        filteredRooms.map((room) => (
          <RoomRow
            key={room.id}
            room={room}
            onJoinGame={onJoinGame}
            onJoinPrivateGame={onJoinPrivateGame}
            isLoading={isLoading}
          />
        ))
      ) : (
        <View
          style={{
            justifyContent: 'center',
            paddingHorizontal: ds.padding[7],
            paddingTop: ds.padding[5],
          }}
        >
          <CreteRoundText
            style={{
              textAlign: 'center',
              fontSize: ds.fontSize[1],
              color: ds.primary70,
            }}
          >
            {search ? i18n.t('no-search-results') : i18n.t('no-public-rooms')}
          </CreteRoundText>
          <CreteRoundText
            style={{
              textAlign: 'center',
              fontSize: ds.fontSize[0],
              color: ds.primary50,
            }}
          >
            {search
              ? i18n.t('no-search-results-description')
              : i18n.t('no-public-rooms-description')}
          </CreteRoundText>
        </View>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    alignSelf: 'center',
    width: '90%',
    maxWidth: 700,
  },
  gameTitleContainer: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: ds.padding[3],
  },
  gameTitle: {
    fontSize: ds.fontSize[3],
    marginLeft: ds.margin[1],
  },
  searchContainer: {
    backgroundColor: ds.almostWhite,
    width: '100%',
    alignSelf: 'center',
    maxWidth: 700,
    marginBottom: ds.margin[3],
    paddingTop: ds.padding[2],
  },
  roomsContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 700,
  },
  roomNumber: {
    display: 'flex',
    borderRadius: 4,
    overflow: 'hidden',
    width: 30,
    height: 30,
    justifyContent: 'center',
    backgroundColor: ds.primary,
  },
  createGameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: ds.margin[5],
    maxWidth: 700,
  },
  createGameButton: {
    width: '100%',
    justifyContent: 'space-between',
    paddingVertical: ds.padding[3],
    paddingHorizontal: ds.padding[4],
  },
  createGamePrompt: {
    opacity: 0.5,
    margin: ds.margin[2],
  },
});

export default JoinGame;
