import React, { useContext, useEffect, useState } from 'react';
import { TextInput, StyleSheet, View, TouchableOpacity } from 'react-native';

import Popup from '../../components/Popup';
import { SettingsContext } from '../../DefaultContainer';
import * as ds from '../../../assets/defaultStyles';
import ArchivoText from '../../components/text/ArchivoText';
import { Feather } from '@expo/vector-icons';

function NumericKeyboard({ onKeyPress, onClear, onDelete }) {
  return (
    <View style={styles.keyboard}>
      <KeyboardRow numbers={[1, 2, 3]} onKeyPress={onKeyPress} />
      <KeyboardRow numbers={[4, 5, 6]} onKeyPress={onKeyPress} />
      <KeyboardRow numbers={[7, 8, 9]} onKeyPress={onKeyPress} />
      <View style={styles.keyboardRow}>
        <TouchableOpacity style={styles.key} onPress={onClear}>
          <Feather name='x' size={ds.fontSize[2]} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.key} onPress={() => onKeyPress(0)}>
          <ArchivoText style={{ fontSize: ds.fontSize[3] }}>0</ArchivoText>
        </TouchableOpacity>
        <TouchableOpacity style={styles.key} onPress={onDelete}>
          <Feather name='delete' size={ds.fontSize[2]} />
        </TouchableOpacity>
      </View>
    </View>
  );
}

function KeyboardRow({ numbers, onKeyPress }) {
  return (
    <View style={styles.keyboardRow}>
      {numbers.map((number) => (
        <TouchableOpacity
          key={number}
          style={styles.key}
          onPress={() => onKeyPress(number)}
        >
          <ArchivoText style={{ fontSize: ds.fontSize[3] }}>
            {number}
          </ArchivoText>
        </TouchableOpacity>
      ))}
    </View>
  );
}

function JoinPrivateRoomPopup({ onHide, onJoinRoom, isLoading, roomId }) {
  const { i18n } = useContext(SettingsContext);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (password.length === 4) {
      onJoinRoom(password);
    }
  }, [password]);

  return (
    <Popup
      title={i18n.t('join-private-game') + ' ' + roomId}
      description={i18n.t('join-private-game-description')}
      hidePopup={onHide}
    >
      <View style={styles.passwordInput}>
        {[0, 1, 2, 3].map((number) => (
          <View
            key={number}
            style={{
              borderBottomColor: ds.primary,
              borderBottomWidth: 2,
              padding: ds.padding[0],
              margin: ds.margin[0],
            }}
          >
            <ArchivoText
              style={{
                opacity: password.charAt(number) ? 1 : 0.05,
                fontSize: ds.fontSize[3],
              }}
            >
              {password.charAt(number) ? password.charAt(number) : 0}
            </ArchivoText>
          </View>
        ))}
      </View>
      <NumericKeyboard
        onKeyPress={(number) => setPassword(password + number)}
        onClear={() => setPassword('')}
        onDelete={() => setPassword(password.slice(0, -1))}
      />
    </Popup>
  );
}

const styles = StyleSheet.create({
  passwordInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ds.white,
    borderRadius: 6,
    height: 60,
    width: '100%',
    fontFamily: 'crete-round-regular',
    fontSize: ds.fontSize[1],
    paddingHorizontal: ds.padding[4],
    marginVertical: ds.margin[3],
    color: ds.primary,
  },
  keyboard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  keyboardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  key: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: ds.padding[3],
    paddingHorizontal: ds.padding[6],
  },
});

export default JoinPrivateRoomPopup;
