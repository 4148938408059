import React from 'react';
import { GameContext } from '../DefaultContainer';

import HomeScreen from './Home/HomeScreen';
import RoomScreen from './Game/RoomScreen';
import GameScreen from './Game/GameScreen';

const MultiScreen = ({ navigation }) => {
  const { game } = React.useContext(GameContext);

  if (game && game.roomId) {
    if (!game.match || (game.match && game.match.finished)) {
      return <RoomScreen />;
    }
    return <GameScreen />;
  }

  return <HomeScreen navigation={navigation} />;
};

export default MultiScreen;
