import React, { useContext } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import * as allCardStyles from '../../assets/decks';
import * as defaultStyles from '../../assets/defaultStyles';
import { SettingsContext } from '../DefaultContainer';
import Card from './Card';
import CreteRoundText from './text/CreteRoundText';

const AllCardStyles = () => {
  const { settings, changeCards, i18n } = useContext(SettingsContext);

  const selectedCardsStyle = (cardStyle) => {
    if (settings.cards.name === cardStyle) {
      return {
        backgroundColor: defaultStyles.primary30,
        borderRadius: 6,
      };
    }
  };

  return Object.entries(allCardStyles).map(([styleName, cardStyle]) => {
    return (
      <TouchableOpacity
        key={styleName}
        style={[styles.cardOption, selectedCardsStyle(styleName)]}
        onPress={() => changeCards(cardStyle)}
      >
        <CreteRoundText style={styles.cardOptionTitle}>
          {i18n.t(`${styleName}-cards`)}
        </CreteRoundText>
        <View style={styles.cardPreviewContainer}>
          {['H_7', 'C_9', 'S_J', 'D_A'].map((cardTitle) => (
            <Card
              key={cardTitle}
              height={60}
              width={60}
              card={cardStyle[cardTitle]}
              style={styles.previewCard}
            />
          ))}
        </View>
      </TouchableOpacity>
    );
  });
};

const styles = StyleSheet.create({
  cardOptionTitle: { fontSize: defaultStyles.fontSize[1] },
  cardOption: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: defaultStyles.padding[3],
    width: '100%',
  },
  cardPreviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  previewCard: {
    marginLeft: -30,
  },
});

export default AllCardStyles;
