export const green = '#5EA87E';
export const green10 = '#5EA87E10';
export const green30 = '#5EA87E4D';
export const red = '#EE6569';
export const darkRed = '#AB4C54';
export const yellow = '#FFFCAD';
export const orange = '#EDB443';

export const primary = '#2A3147';
export const primaryLight = '#6A6F7E';
export const primary10 = '#2A314710';
export const primary30 = '#2A31474D';
export const primary50 = '#2A314780';
export const primary70 = '#2A3147B3';

export const black = '#000';
export const black0 = '#0000';
export const almostWhite = '#F4F4F4';
export const almostWhite0 = '#F4F4F400';
export const white = '#FFF';
export const white0 = '#FFF0';
export const white50 = '#FFFFFF80';
export const white70 = '#FFFFFFB3';

export const margin = [5, 8, 12, 16, 24, 32, 40, 50, 60, 70, 80, 90, 100];
export const padding = [
  5,
  8,
  12,
  16,
  24,
  32,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  120,
  150,
];
export const fontSize = [16, 20, 24, 32, 36, 44, 50, 57, 60];
