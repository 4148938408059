import React, { useEffect, useState } from 'react';
import { View, Animated } from 'react-native';
import * as defaultStyles from '../../assets/defaultStyles';
import usePrevious from '../hooks/usePrevious';

interface ProgressParProps {
  percentage: number;
}

function ProgressBar({ percentage }: ProgressParProps) {
  const backgroundColor = (): string => {
    if (percentage < 0.25) return defaultStyles.red;
    if (percentage < 0.5) return defaultStyles.orange;
    return defaultStyles.green;
  };

  return (
    <View
      style={{
        backgroundColor: defaultStyles.primary10,
        borderRadius: 5,
        height: 7,
        width: '100%',
      }}
    >
      <View
        style={{
          height: 7,
          borderRadius: 5,
          width: `${Math.min(Math.max(percentage, 0), 1) * 100}%`,
          backgroundColor: backgroundColor(),
        }}
      ></View>
    </View>
  );
}

export default ProgressBar;
