import Constants from 'expo-constants';
import { Dimensions, Platform } from 'react-native';
import { http } from '../http';

export const sendBasicUserInfo = async (user, settings) => {
  if (user && settings) {
    try {
      await http.post('/user/settings', {
        id: user.id,
        settings: {
          ...settings,
          cards: settings.cards.name,
          platform: {
            OS: Platform.OS,
            isPad: Platform.isPad,
            isTV: Platform.isTV,
            Version: Platform.Version,
          },
          constants: {
            deviceName: Constants.deviceName,
            deviceYearClass: Constants.deviceYearClass,
            installationId: Constants.installationId,
          },
          appVersion: Constants.manifest.version,
          screen: Dimensions.get('window'),
        },
      });
    } catch (e) {
      console.warn(e);
    }
  }
};
