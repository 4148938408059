import React from 'react';
import { Image, StyleSheet } from 'react-native';

const Suit = ({ width = 100, height = 100, suit, style, scale = 1 }) => {
  const scaledWidth = width * scale;
  const scaledHeight = height * scale;

  return (
    <Image
      source={suit.img}
      style={[{ width: scaledWidth, height: scaledHeight }, style]}
      fadeDuration={0}
      resizeMode='contain'
    />
  );
};

const styles = StyleSheet.create({});

export default Suit;
