import React, { useContext } from 'react';
import { View } from 'react-native';
import * as defaultStyles from '../../../assets/defaultStyles';
import CreteRoundText from '../../components/text/CreteRoundText';
import { GameContext } from '../../DefaultContainer';
import DeclarationRow from './DeclarationRow';

function Declarations({ declarations }) {
  const { game } = useContext(GameContext);

  const getPlayerName = (playerId) => {
    if (game) {
      if (game.leftOpponent.id === playerId) return game.leftOpponent.name;
      if (game.teammate.id === playerId) return game.teammate.name;
      if (game.rightOpponent.id === playerId) return game.rightOpponent.name;
      if (game.me.id === playerId) return game.me.name;
    }
  };

  return (
    declarations.length > 0 &&
    declarations.map((declaration, index) => {
      return (
        <View
          key={'main' + declaration.suit + declaration.value + index}
          style={{ width: '100%' }}
        >
          <CreteRoundText
            key={'title' + declaration.suit + declaration.value + index}
            white
            style={{
              fontSize: defaultStyles.fontSize[2],
              alignSelf: 'flex-start',
            }}
          >
            {getPlayerName(declaration.playerId)}
          </CreteRoundText>
          <DeclarationRow
            key={'decrow' + declaration.suit + declaration.value + index}
            declaration={declaration}
          />
        </View>
      );
    })
  );
}

export default Declarations;
