import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import en from '../assets/lang/en';
import enReactions from '../assets/lang/en-reactions';
import hr from '../assets/lang/hr';
import hrReactions from '../assets/lang/hr-reactions';

hr.reactions = hrReactions;
en.reactions = enReactions;

i18n.translations = {
  en,
  hr,
};

i18n.defaultLocale = 'en';
i18n.fallbacks = true;
i18n.locale = Localization.locale;

export default i18n;

export function setLocale(lang) {
  i18n.locale = lang;
}
