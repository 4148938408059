import React, { useContext, useEffect } from 'react';
import { View, Vibration, Platform } from 'react-native';
import ProgressBar from '../../components/ProgressBar';
import ArchivoText from '../../components/text/ArchivoText';
import * as defaultStyles from '../../../assets/defaultStyles';
import { SettingsContext } from '../..//DefaultContainer';

interface TimeForMoveProps {
  timeLeft: number;
  totalTime: number;
  vibrate: boolean;
}

function square(value: number) {
  return value * value;
}

function TimeForMove({ timeLeft, totalTime, vibrate }: TimeForMoveProps) {
  const { i18n, settings } = useContext(SettingsContext);
  const value = timeLeft / totalTime;
  const percentage = 1 - square(1 - value);

  useEffect(() => {
    if (Platform.OS !== 'ios') return;
    if (!vibrate || !settings.haptics) return;
    if (percentage < 0.3) {
      Vibration.vibrate(400);
    }
    return () => {
      Vibration.cancel();
    };
  }, [percentage]);

  return (
    <View
      style={{
        position: 'absolute',
        bottom: -5,
        left: 0,
        width: 130,
        transform: [{ translateY: 57 }],
        display: 'flex',
      }}
    >
      <View
        style={{
          padding: defaultStyles.padding[2],
          borderRadius: 10,
          display: 'flex',
        }}
      >
        <ArchivoText
          style={{ marginBottom: defaultStyles.margin[0], alignSelf: 'center' }}
          primary
        >
          {i18n.t('time-for-move')}
        </ArchivoText>
        <ProgressBar percentage={percentage} />
      </View>
    </View>
  );
}

export default TimeForMove;
