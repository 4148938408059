import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';

import { SettingsContext } from '../../DefaultContainer';
import * as defaultStyles from '../../../assets/defaultStyles';
import CreteRoundText from '../../components/text/CreteRoundText';

function ServerMessage({ serverMessage }) {
  const { i18n } = useContext(SettingsContext);

  return (
    <View style={styles.mainContainer}>
      <View style={styles.instructionsContainer}>
        <CreteRoundText
          white={true}
          style={{ fontSize: defaultStyles.fontSize[0], textAlign: 'center' }}
        >
          {i18n.t(serverMessage.message)}
        </CreteRoundText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    position: 'absolute',
    top: 50,
    left: 30,
    right: 30,
    zIndex: 1000,
    elevation: 1000,
    display: 'flex',
    alignItems: 'center',
  },
  instructionsContainer: {
    backgroundColor: defaultStyles.red,
    paddingVertical: defaultStyles.padding[0],
    paddingHorizontal: defaultStyles.padding[2],
    borderRadius: 6,
  },
});

export default ServerMessage;
