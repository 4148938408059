import React from 'react';
import { StyleSheet, View, useWindowDimensions, Platform } from 'react-native';
import * as ds from '../../assets/defaultStyles';
import CreteRoundText from './text/CreteRoundText';
import { SettingsContext } from '../DefaultContainer';
import { useContext } from 'react';
import LottieView from 'lottie-react-native';

const LoadingOverlay = () => {
  const screenWidth = useWindowDimensions().width;
  const screenHeight = useWindowDimensions().height;

  const { i18n } = useContext(SettingsContext);

  return (
    <View
      style={{
        zIndex: 1000,
        elevation: 1000,
        position: 'absolute',
        top: 0,
        left: 0,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: screenWidth,
        height: screenHeight,
        backgroundColor: ds.primary30,
      }}
    >
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '40%',
          maxWidth: 500,
          padding: ds.padding[5],
          backgroundColor: 'white',
          borderRadius: 10,

          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 6,
          },
          shadowOpacity: 0.1,
          shadowRadius: 10,

          elevation: 12,
        }}
      >
        <View
          style={{
            width: Platform.OS === 'web' ? 0 : 50,
            height: Platform.OS === 'web' ? 0 : 50,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {Platform.OS !== 'web' && (
            <LottieView
              source={require('../../assets/animations/cards.json')}
              autoPlay
              loop
              style={{ height: 50, width: 50 }}
            />
          )}
        </View>
        <CreteRoundText
          style={{
            fontSize: ds.fontSize[0],
            marginTop: Platform.OS === 'web' ? 0 : ds.margin[4],
          }}
          primary
        >
          {i18n.t('loading')}
        </CreteRoundText>
      </View>
    </View>
  );
};

export default LoadingOverlay;

const styles = StyleSheet.create({});
