const avatars = [
  {
    name: 'C_K',
    img: require('./avatar-C_K.png'),
  },
  {
    name: 'C_Q',
    img: require('./avatar-C_Q.png'),
  },
  {
    name: 'C_J',
    img: require('./avatar-C_J.png'),
  },
  {
    name: 'D_K',
    img: require('./avatar-D_K.png'),
  },
  {
    name: 'D_Q',
    img: require('./avatar-D_Q.png'),
  },
  {
    name: 'D_J',
    img: require('./avatar-D_J.png'),
  },
  {
    name: 'H_K',
    img: require('./avatar-H_K.png'),
  },
  {
    name: 'H_Q',
    img: require('./avatar-H_Q.png'),
  },
  {
    name: 'H_J',
    img: require('./avatar-H_J.png'),
  },
  {
    name: 'S_K',
    img: require('./avatar-S_K.png'),
  },
  {
    name: 'S_Q',
    img: require('./avatar-S_Q.png'),
  },
  {
    name: 'S_J',
    img: require('./avatar-S_J.png'),
  },
];

export default avatars;
