export default {
  sad: {
    emoji: '😢',
    text: 'Noo',
  },
  awesome: {
    emoji: '🔥',
    text: 'Awesome',
  },
  angry: {
    emoji: '😡',
    text: 'Argh!',
  },
  celebrate: {
    emoji: '🎉',
    text: 'Nice',
  },
  oops: {
    emoji: '🙃',
    text: 'Oops',
  },
  mock: {
    emoji: '😜',
    text: 'You are too good',
  },
  bela: {
    emoji: '🤘',
    text: 'Bela!',
  },
};
