import React from 'react';
import { View, Text, StyleSheet, TouchableWithoutFeedback } from 'react-native';
// import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import * as ds from '../../../assets/defaultStyles';

interface ToggleProps {
  enabled: boolean;
  onPress?: () => {};
  width?: number;
  height?: number;
  customHead?: React.ReactNode;
  enabledColor?: string;
  disabledColor?: string;
}

const Toggle = ({
  onPress,
  enabled,
  width = 65,
  height = 37,
  customHead,
  enabledColor = ds.green,
  disabledColor = '#B6B8BD',
}: ToggleProps) => {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View
        style={[
          styles.container,
          {
            width,
            height,
            flexDirection: enabled ? 'row-reverse' : 'row',
            backgroundColor: enabled ? enabledColor : disabledColor,
          },
        ]}
      >
        {customHead ? customHead : <View style={[styles.toggleHead]}></View>}
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 6,
  },
  toggleHead: {
    width: '45%',
    backgroundColor: ds.almostWhite,
    borderRadius: 4,
    margin: ds.padding[0],

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,

    elevation: 12,
  },
});

export default Toggle;
