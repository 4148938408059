import React, { useContext } from 'react';

import Popup from '../../components/Popup';
import AllCardStyles from '../../components/AllCardStyles';
import * as defaultStyles from '../../../assets/defaultStyles';
import CreteRoundText from '../../components/text/CreteRoundText';

import { SettingsContext, UserContext } from '../../DefaultContainer';

function CardStylePopup({ onHide }) {
  const { user } = useContext(UserContext);
  const { i18n } = useContext(SettingsContext);

  return (
    <Popup
      title={i18n.t('choose-card-style')}
      description={
        user.appVersion
          ? i18n.t('choose-card-style-description-new-player')
          : i18n.t('choose-card-style-description-old-player')
      }
      hidePopup={onHide}
      options={[
        {
          value: i18n.t('close'),
          style: {
            alignItems: 'flex-end',
            width: '90%',
          },
        },
      ]}
    >
      <AllCardStyles />
      <CreteRoundText
        style={{ opacity: 0.4, paddingTop: defaultStyles.padding[1] }}
      >
        {i18n.t('you-can-change-in-settings')}
      </CreteRoundText>
    </Popup>
  );
}

export default CardStylePopup;
