import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import * as defaultStyles from '../../assets/defaultStyles';
import ArchivoText from './text/ArchivoText';

const TemporaryPopup = (props) => {
  const {
    onBlur = () => {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 200,
        delay: props.delay ? props.delay : 3000,
        easing: Easing.inOut(Easing.ease),
        useNativeDriver: true,
      }).start(() => {
        if (props.hidePopup()) {
          props.hidePopup();
        }
      });
    },
  } = props;

  const [fadeAnim] = useState(new Animated.Value(0));
  const { title = 'Your Title' } = props;

  const platformContainer = () => {
    return Platform.OS === 'web' ? styles.webContainer : styles.container;
  };

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 200,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: true,
    }).start(() => {
      onBlur();
    });

    return () => {};
  }, []);

  return (
    <View
      style={[
        platformContainer(),
        {
          width: Dimensions.get('window').width,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Animated.View
        style={[
          styles.popup,
          props.style,
          {
            opacity: fadeAnim,
            transform: [
              {
                translateY: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [20, 0],
                }),
              },
            ],
          },
        ]}
      >
        <View style={styles.titleContainer}>
          <ArchivoText bold white style={styles.popupTitle}>
            {title}
          </ArchivoText>
          <TouchableOpacity
            onPress={() => {
              if (props.hidePopup) {
                props.hidePopup();
              }
            }}
          >
            <Ionicons name='ios-close' size={40} color={defaultStyles.white} />
          </TouchableOpacity>
        </View>
        <View {...props} style={[styles.defaultBodyStyle, props.style]}>
          {props.children}
        </View>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  webContainer: {
    zIndex: 1000,
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    zIndex: 2000,
    elevation: 2000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  popup: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    maxWidth: 500,
    elevation: 3,
    backgroundColor: defaultStyles.primary,
    padding: defaultStyles.padding[4],
    // paddingBottom: defaultStyles.padding[6],
    borderRadius: 7,
    // marginTop: Platform.OS === 'web' ? 0 : 150,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,

    elevation: 12,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  popupTitle: {
    fontSize: defaultStyles.fontSize[3],
  },
  defaultBodyStyle: {
    width: '100%',
  },
});

export default TemporaryPopup;
