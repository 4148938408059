import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as ScreenOrientation from 'expo-screen-orientation';
import React, { useState } from 'react';
import { Image, Platform, StatusBar, StyleSheet } from 'react-native';
import avatars from './assets/avatars';
import { hungarian, modern } from './assets/decks';
import { modernSuits } from './assets/suits';
import DefaultContainer from './src/DefaultContainer';
import './src/localization';
import Routes from './src/Routes';
import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn:
    'https://c19e74e2f5e84d27932d4e70c2fcb060@o506377.ingest.sentry.io/5595985',
  enableInExpoDevelopment: true,
  debug: false,
});

const App = () => {
  const [resourcesLoaded, setResourcesLoaded] = useState(false);

  if (!resourcesLoaded)
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setResourcesLoaded)}
      />
    );

  return (
    <DefaultContainer>
      <StatusBar
        barStyle={Platform.OS === 'android' ? 'light-content' : 'dark-content'}
      />
      <Routes />
    </DefaultContainer>
  );
};

function cacheImages(images) {
  return images.map((image) => {
    if (!image) return;
    if (typeof image === 'string') {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

function cacheFonts(fonts) {
  return fonts.map((font) => Font.loadAsync(font));
}

async function loadResourcesAsync() {
  const fontAssets = cacheFonts([
    Ionicons.font,
    MaterialCommunityIcons.font,
    { 'archivo-bold': require('./assets/fonts/Archivo-Bold.ttf') },
    { 'archivo-semibold': require('./assets/fonts/Archivo-SemiBold.ttf') },
    { 'archivo-bold-italic': require('./assets/fonts/Archivo-BoldItalic.ttf') },
    { 'archivo-medium': require('./assets/fonts/Archivo-Medium.ttf') },
    { 'archivo-italic': require('./assets/fonts/Archivo-Italic.ttf') },
    { 'archivo-regular': require('./assets/fonts/Archivo-Regular.ttf') },
    { 'crete-round-regular': require('./assets/fonts/CreteRound-Regular.ttf') },
    { 'crete-round-italic': require('./assets/fonts/CreteRound-Italic.ttf') },
  ]);

  await Promise.all([...fontAssets]);

  const imageAssets = cacheImages([
    ...avatars.map((avatar) => avatar.img),
    ...Object.keys(modern).map((card) => modern[card].img),
    ...Object.keys(hungarian).map((card) => hungarian[card].img),
    ...Object.keys(modernSuits).map((suit) => modernSuits[suit].img),
  ]);
  await Promise.all([...imageAssets]);

  if (Platform.isPad) {
    await ScreenOrientation.unlockAsync();
  }
}

function handleLoadingError(error) {
  console.warn(error);
}
function handleFinishLoading(resourcesLoaded) {
  resourcesLoaded(true);
}

const styles = StyleSheet.create({});

export default App;
