import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import React, { useContext } from 'react';
import { StyleSheet, View, Linking } from 'react-native';
import * as defaultStyles from '../../assets/defaultStyles';
import { SettingsContext, UserContext } from '../DefaultContainer';
import Avatar from './Avatar';
import CreteRoundText from './text/CreteRoundText';

function BelotDrawer(props) {
  const { user } = useContext(UserContext);
  const { i18n } = useContext(SettingsContext);

  return (
    <>
      <View style={styles.userContainer}>
        <CreteRoundText primary style={styles.username}>
          {user.nick}
        </CreteRoundText>
        <View style={styles.avatarContainer}>
          <Avatar
            avatar={user.avatar}
            width={80}
            height={80}
            style={{
              backgroundColor: defaultStyles.white,
            }}
          />
        </View>
      </View>
      <DrawerContentScrollView {...props}>
        <DrawerItemList {...props} />
        <DrawerItem
          labelStyle={{
            fontFamily: 'crete-round-regular',
            fontSize: defaultStyles.fontSize[0],
          }}
          label={i18n.t('donate')}
          onPress={() =>
            Linking.openURL('https://www.buymeacoffee.com/sombrerito')
          }
        />
      </DrawerContentScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: defaultStyles.padding[2],
    paddingTop: defaultStyles.padding[4],
  },
  username: {
    fontSize: defaultStyles.fontSize[1],
    maxWidth: '100%',
    flexShrink: 1,
    textAlign: 'right',
    paddingRight: defaultStyles.padding[1],
  },
  avatarContainer: {
    borderRadius: 50,
    margin: defaultStyles.margin[1],
    overflow: 'hidden',
    borderWidth: 4,
    borderColor: defaultStyles.primary,
    marginHorizontal: defaultStyles.margin[2],

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,

    elevation: 12,
  },
});

export default BelotDrawer;
