import React, { useContext, useEffect, useState } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  StyleSheet,
  View,
  Platform,
  Linking,
} from 'react-native';
import * as ds from '../../../assets/defaultStyles';
import TopBar from '../../components/TopBar';
import {
  GameContext,
  SettingsContext,
  UserContext,
} from '../../DefaultContainer';
import { http } from '../../http';
import CardStylePopup from './CardStylePopup';
import CreateRoomPopup from './CreateRoomPopup';
import JoinPrivateRoomPopup from './JoinPrivateRoomPopup';
import JoinGame from './JoinGame';
import ServerMessage from './ServerMessage';
import UserDetails from './UserDetails';
import {
  SafeAreaView,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import PrimaryButton from '../../components/PrimaryButton';
import { Feather } from '@expo/vector-icons';
import LoadingOverlay from '../../components/LoadingOverlay';
import Popup from '../../components/Popup';

const HomeScreen = ({ navigation }) => {
  const [input, setInput] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showSetCardStylePopup, setShowSetCardStylePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [joinRoomPopup, setJoinRoomPopup] = useState({ show: false });
  const [donatePopup, setDonatePopup] = useState(false);
  const [donateEmphasis] = useState(Math.random() < 0.05);

  const { user, initializeUser } = useContext(UserContext);
  const { settings, setDefaultCards, i18n } = useContext(SettingsContext);
  const {
    connection,
    initializeConnection,
    setServerMessageForInterval,
    serverMessage,
    rooms,
  } = useContext(GameContext);

  const createGame = async (maxPoints, bestOf = 1) => {
    setIsLoading(true);
    try {
      const response = await http.post('/createRoom', {
        id: user.id,
        maxPoints,
        bestOf,
      });
      if (response.data.roomId) {
        await joinRoom(response.data.roomId);
      }
    } catch (e) {
      if (
        e.response.data &&
        e.response.data.message === 'ERROR_PLAYER_DOESNT_EXIST'
      ) {
        initializeUser();
        return;
      }
      setServerMessageForInterval(e.response.data);
      setShowPopup(false);
    }
    setTimeout(() => setIsLoading(false), 500);
  };

  const joinRoom = async (roomId, roomPassword) => {
    setIsLoading(true);
    try {
      const response = await http.post(`/joinRoom?hash=${roomId}`, {
        id: user.id,
        roomId,
        roomPassword,
      });
      if (!connection && response.data.roomId) {
        initializeConnection(response.data.roomId);
      }
    } catch (e) {
      if (
        e.response.data &&
        e.response.data.message === 'ERROR_PLAYER_DOESNT_EXIST'
      ) {
        initializeUser();
        return;
      }
      setServerMessageForInterval(e.response.data);
    }
    setTimeout(() => setIsLoading(false), 500);
  };

  const onJoinGame = async (roomId, password) => {
    if (roomId.length > 0) {
      await joinRoom(roomId, password);
    }
  };

  useEffect(() => {
    if (!settings || !settings.cardsChosen) {
      setShowSetCardStylePopup(true);
    }
  }, []);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {serverMessage && <ServerMessage serverMessage={serverMessage} />}
      <SafeAreaView style={{ height: '100%' }}>
        <KeyboardAvoidingView behavior='height' style={styles.mainContainer}>
          <TopBar
            navigation={navigation}
            donatePress={() => setDonatePopup(true)}
            donateEmphasis={donateEmphasis}
          />
          <UserDetails navigation={navigation} user={user} />
          <JoinGame
            roomId={input}
            setRoomId={setInput}
            onJoinGame={onJoinGame}
            onJoinPrivateGame={(roomId) => {
              setJoinRoomPopup({ show: true, roomId });
            }}
            rooms={rooms}
            isLoading={isLoading}
          />
        </KeyboardAvoidingView>
        <View style={styles.createGameContainer}>
          <PrimaryButton
            title={i18n.t('create-game')}
            style={styles.createGameButton}
            onPress={() => {
              Keyboard.dismiss();
              setShowPopup(true);
            }}
            disabled={isLoading}
            tint={ds.green}
            icon={<Feather name='plus' size={32} color={ds.white} />}
          />
        </View>
        {showSetCardStylePopup && (
          <CardStylePopup
            onHide={() => {
              setDefaultCards();
              setShowSetCardStylePopup(false);
            }}
          />
        )}
      </SafeAreaView>
      {showPopup && (
        <CreateRoomPopup
          onHide={() => setShowPopup(false)}
          onCreateGame={async (points) => await createGame(points)}
          isLoading={isLoading}
        />
      )}
      {joinRoomPopup.show && (
        <JoinPrivateRoomPopup
          roomId={joinRoomPopup.roomId}
          onHide={() => setJoinRoomPopup({ show: false })}
          onJoinRoom={async (password) =>
            await onJoinGame(joinRoomPopup.roomId, password)
          }
          isLoading={isLoading}
        />
      )}
      {donatePopup && (
        <Popup
          title={'💸 ' + i18n.t('donate')}
          description={i18n.t('donate-description')}
          hidePopup={() => {
            setDonatePopup(false);
          }}
          options={[
            {
              value: i18n.t('close'),
              color: ds.red,
            },
            {
              value: i18n.t('donate'),
              color: ds.green,
              action: () => {
                Linking.openURL(
                  `https://app.bela.fun/donate-${Platform.OS}-${
                    donateEmphasis ? 'button' : 'icon'
                  }`
                );
              },
            },
          ]}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  createGameContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom:
      initialWindowMetrics && Platform.OS === 'ios'
        ? initialWindowMetrics.insets.bottom
        : 0,
    backgroundColor: ds.almostWhite,
    paddingBottom: ds.padding[4],
  },
  createGameButton: {
    width: '90%',
    maxWidth: 700,
    justifyContent: 'space-between',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ds.almostWhite,
    height: '100%',
    width: '100%',
  },
  avatar: {
    backgroundColor: ds.white,
  },
});

export default HomeScreen;
