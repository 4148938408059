import { Ionicons } from '@expo/vector-icons';
import React, { useContext, useEffect, useState } from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import * as defaultStyles from '../../../assets/defaultStyles';
import Card from '../../components/Card';
import { SettingsContext } from '../../DefaultContainer';
import Player from './Player/Player';

const CardTable = (props) => {
  const { north, east, south, west, game, leaveAction } = props;
  const { settings } = useContext(SettingsContext);

  const aspectRatio =
    Dimensions.get('screen').height / Dimensions.get('screen').width;

  const scale = () => {
    if (Dimensions.get('window').width < 400 && aspectRatio < 1.8) return 1;
    else if (Dimensions.get('window').width < 400) return 1.2;
    return 1.5;
  };
  const cardWidth = 50 * scale();
  const cardHeight = 70 * scale();

  const [northCard, setNorthCard] = useState(null);
  const [southCard, setSouthCard] = useState(null);
  const [eastCard, setEastCard] = useState(null);
  const [westCard, setWestCard] = useState(null);

  const [westAnim] = useState(new Animated.Value(0));
  const [eastAnim] = useState(new Animated.Value(0));
  const [northAnim] = useState(new Animated.Value(0));
  const [southAnim] = useState(new Animated.Value(0));

  const [zIndex, setZIndex] = useState({
    south: 0,
    west: 0,
    north: 0,
    east: 0,
  });

  const maxZIndex = () => {
    let max = 0;
    Object.keys(zIndex).forEach((el) => {
      if (zIndex[el] > max) max = zIndex[el];
    });
    return max;
  };

  useEffect(() => {
    if (east !== null) {
      setEastCard(east);
      setZIndex({ ...zIndex, east: maxZIndex() + 1 });
    } else {
      setZIndex({ ...zIndex, east: 0 });
      Animated.timing(eastAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.in,
        useNativeDriver: true,
      }).start(() => {
        setEastCard(null);
      });
    }
  }, [east]);

  useEffect(() => {
    setEastCard(east);
    setWestCard(west);
    setNorthCard(north);
    setSouthCard(south);
  }, []);

  useEffect(() => {
    if (eastCard) {
      Animated.timing(eastAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.bounce,
        useNativeDriver: true,
      }).start();
    }
  }, [eastCard]);

  useEffect(() => {
    if (west !== null) {
      setWestCard(west);
      setZIndex({ ...zIndex, west: maxZIndex() + 1 });
    } else {
      setZIndex({ ...zIndex, west: 0 });
      Animated.timing(westAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.in,
      }).start(() => {
        setWestCard(null);
      });
    }
  }, [west]);

  useEffect(() => {
    if (westCard) {
      Animated.timing(westAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.bounce,
        useNativeDriver: true,
      }).start();
    }
  }, [westCard]);

  useEffect(() => {
    if (north !== null) {
      setNorthCard(north);
      setZIndex({ ...zIndex, north: maxZIndex() + 1 });
    } else {
      setZIndex({ ...zIndex, north: 0 });
      Animated.timing(northAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.in,
        useNativeDriver: true,
      }).start(() => {
        setNorthCard(null);
      });
    }
  }, [north]);

  useEffect(() => {
    if (northCard) {
      Animated.timing(northAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.bounce,
        useNativeDriver: true,
      }).start();
    }
  }, [northCard]);

  useEffect(() => {
    if (south !== null) {
      setSouthCard(south);
      setZIndex({ ...zIndex, south: maxZIndex() + 1 });
    } else {
      setZIndex({ ...zIndex, south: 0 });
      Animated.timing(southAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.in,
        useNativeDriver: true,
      }).start(() => {
        setSouthCard(null);
      });
    }
  }, [south]);

  useEffect(() => {
    if (southCard) {
      Animated.timing(southAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.bounce,
        useNativeDriver: true,
      }).start();
    }
  }, [southCard]);

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.leaveRoomIcon} onPress={leaveAction}>
        <Ionicons name='ios-log-out' size={30} />
      </TouchableOpacity>
      {game.teammate && (
        <Player
          player={game.teammate}
          position='top'
          active={game.currentPlayer.id === game.teammate.id}
        />
      )}
      {game.leftOpponent && (
        <Player
          player={game.leftOpponent}
          position='left'
          active={game.currentPlayer.id === game.leftOpponent.id}
        />
      )}
      {game.rightOpponent && (
        <Player
          player={game.rightOpponent}
          position='right'
          active={game.currentPlayer.id === game.rightOpponent.id}
        />
      )}
      {east && (
        <View
          style={[
            {
              zIndex: east.index,
              elevation: east.index,
              transform: [
                { translateY: -cardHeight / 2 },
                { translateX: cardWidth + 15 },
                { rotate: '12deg' },
              ],
              top: '50%',
              right: '50%',
            },
            styles.cardWrapper,
          ]}
        >
          <Card
            height={cardHeight}
            width={cardWidth}
            card={settings.cards[east.card.str]}
          />
        </View>
      )}
      {west && (
        <View
          style={[
            {
              zIndex: west.index,
              elevation: west.index,
              transform: [
                { translateY: -cardHeight / 2 },
                { translateX: -(cardWidth + 10) },
                { rotate: '-11deg' },
              ],
              top: '50%',
              left: '50%',
            },
            styles.cardWrapper,
          ]}
        >
          <Card
            height={cardHeight}
            width={cardWidth}
            card={settings.cards[west.card.str]}
          />
        </View>
      )}
      {north && (
        <View
          style={[
            {
              zIndex: north.index,
              elevation: north.index,
              transform: [
                { translateY: -cardHeight - 10 },
                { translateX: -(cardWidth / 2) },
                { rotate: '5deg' },
              ],
              top: '50%',
              left: '50%',
            },
            styles.cardWrapper,
          ]}
        >
          <Card
            height={cardHeight}
            width={cardWidth}
            card={settings.cards[north.card.str]}
          />
        </View>
      )}
      {south && (
        <View
          style={[
            {
              zIndex: south.index,
              elevation: south.index,
              transform: [
                { translateY: 10 },
                { translateX: -(cardWidth / 2) },
                { rotate: '-5deg' },
              ],
              top: '50%',
              left: '50%',
            },
            styles.cardWrapper,
          ]}
        >
          <Card
            height={cardHeight}
            width={cardWidth}
            card={settings.cards[south.card.str]}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 600,
    marginTop: 50,
  },
  leaveRoomIcon: {
    position: 'absolute',
    top: -40,
    right: 10,
    padding: defaultStyles.padding[0],
  },
  cardWrapper: {
    position: 'absolute',
    backgroundColor: '#0000',
    borderRadius: 15,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4.65,

    elevation: 8,
  },
});

export default CardTable;
