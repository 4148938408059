import AsyncStorage from '@react-native-community/async-storage';
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';
import { SETTINGS, USER } from '../constants';

export const saveSettings = async (settings) => {
  if (settings) {
    await AsyncStorage.setItem(SETTINGS, JSON.stringify(settings));
  }
};

export const loadSettings = async (initializeSettings) => {
  const storageSettings = await AsyncStorage.getItem(SETTINGS);
  if (!storageSettings) {
    initializeSettings();
  } else {
    initializeSettings(JSON.parse(storageSettings));
  }
};

export const saveUser = async (user) => {
  if (user) {
    if (Platform.OS === 'web') {
      await AsyncStorage.setItem(USER, JSON.stringify(user));
    } else {
      await SecureStore.setItemAsync(USER, JSON.stringify(user));
    }
  }
};

export const loadUser = async (initializeUser) => {
  const storageUser =
    Platform.OS === 'web'
      ? await AsyncStorage.getItem(USER)
      : await SecureStore.getItemAsync(USER);
  if (!storageUser) {
    initializeUser();
  } else {
    initializeUser(JSON.parse(storageUser));
  }
};
