import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import * as ds from '../../assets/defaultStyles';

interface IsOnlineBadgeProps {
  style: ViewStyle;
  scale: number;
  containerSize: number;
}

const IsOnlineBadge = ({
  style,
  scale = 1,
  containerSize = 10,
}: IsOnlineBadgeProps) => {
  return (
    <View
      style={[
        styles.container,
        {
          width: scale * containerSize,
          height: scale * containerSize,
          borderRadius: (scale * containerSize) / 2,
        },
        style,
      ]}
    ></View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    borderRadius: 10,

    backgroundColor: ds.green,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,

    elevation: 6,
  },
});

export default IsOnlineBadge;
