import React, { useContext } from 'react';
import { View, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { SettingsContext } from '../../DefaultContainer';
import * as defaultStyles from '../../../assets/defaultStyles';
import ArchivoText from '../../components/text/ArchivoText';
import CreteRoundText from '../../components/text/CreteRoundText';
import { Feather } from '@expo/vector-icons';
import Avatar from '../../components/Avatar';
import avatars from '../../../assets/avatars';
import LottieView from 'lottie-react-native';
import IsOnlineBadge from '../../components/IsOnlineBadge';
import { Keyboard } from 'react-native';

function Team({ team }) {
  return (
    <View style={styles.team}>
      {team.map((player) => {
        return (
          <View key={player.id}>
            {player.isOnline && (
              <IsOnlineBadge
                scale={0.7}
                style={{ zIndex: 1, position: 'absolute', top: 0, right: 5 }}
              />
            )}
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginHorizontal: defaultStyles.margin[0],

                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.15,
                shadowRadius: 2,
                elevation: 2,
                borderRadius: 15,

                overflow: Platform.OS === 'ios' ? 'visible' : 'hidden',
              }}
            >
              <Avatar
                avatar={avatars.find((avatar) => avatar.name === player.avatar)}
                width={30}
                height={30}
                borderRadius={15}
                style={{
                  backgroundColor: defaultStyles.white,
                }}
              />
            </View>
          </View>
        );
      })}
    </View>
  );
}

function Badge({ value }) {
  return (
    <View style={styles.badge}>
      <ArchivoText style={styles.badgeText} bold>
        {value}
      </ArchivoText>
    </View>
  );
}

function RoomRow({ room, onJoinPrivateGame, onJoinGame, isLoading }) {
  const { i18n } = useContext(SettingsContext);

  return (
    <TouchableOpacity
      style={styles.roomRow}
      onPress={() => {
        room.isPrivate ? onJoinPrivateGame(room.id) : onJoinGame(room.id);
        Keyboard.dismiss();
      }}
      disabled={isLoading}
    >
      <View style={styles.header}>
        <View style={styles.roomName}>
          <CreteRoundText italic>{room.id}</CreteRoundText>
        </View>
        <View style={styles.details}>
          {room.isPrivate && (
            <View
              style={[
                styles.badge,
                {
                  backgroundColor: defaultStyles.red,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              ]}
            >
              <Feather name='lock' size={14} color={defaultStyles.white} />
            </View>
          )}
          <Badge value={room.maxPoints} />
        </View>
      </View>
      <View style={styles.content}>
        <View style={styles.players}>
          <Team team={room.team1} />
          <CreteRoundText italic>vs</CreteRoundText>
          <Team team={room.team2} />
        </View>
        <View style={styles.icon}>
          <Feather
            name='chevron-right'
            size={32}
            color={defaultStyles.primary}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  roomRow: {
    display: 'flex',
    alignSelf: 'center',
    width: '90%',
    marginVertical: defaultStyles.margin[1],
    overflow: 'hidden',
    backgroundColor: defaultStyles.white,
    padding: defaultStyles.padding[2],
    borderRadius: 4,
  },
  roomName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: defaultStyles.padding[3],
  },
  players: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  team: {
    display: 'flex',
    flexDirection: 'row',
  },
  teamPlayerName: {
    color: defaultStyles.primaryLight,
  },
  badge: {
    backgroundColor: defaultStyles.primary,
    paddingVertical: 4,
    paddingHorizontal: defaultStyles.padding[0],
    borderRadius: 5,
    marginLeft: defaultStyles.margin[0],
  },
  badgeText: {
    color: defaultStyles.white,
    textAlign: 'center',
  },
});

export default RoomRow;
