import React from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import * as defaultStyles from '../../../../assets/defaultStyles';
import CreteRoundText from '../../../components/text/CreteRoundText';

const MessageBubble = (props) => {
  const { message, position } = props;

  let positionStyles;
  let handlePositionStyles;
  if (position === 'top-left') {
    positionStyles = {
      top: -25,
      right: 35,
    };
    handlePositionStyles = {
      bottom: 10,
      right: -37,
      transform: [{ rotateY: '180deg' }],
    };
  } else if (position === 'middle-right') {
    positionStyles = {
      top: -15,
      left: 35,
    };
    handlePositionStyles = {
      bottom: 10,
      left: 0,
    };
  } else {
    positionStyles = {
      top: -25,
      left: 35,
    };
    handlePositionStyles = {
      bottom: 10,
      left: -3,
    };
  }

  return (
    <Animated.View
      style={[styles.bubbleContainer, positionStyles, props.style]}
    >
      <View style={[styles.bubble]}>
        <CreteRoundText primary style={{ width: '100%' }}>
          {message}
        </CreteRoundText>
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  bubbleContainer: {
    position: 'absolute',
    zIndex: 9999,

    backgroundColor: '#0000',

    shadowColor: '#000',
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10.0,
    elevation: 6,
  },
  bubble: {
    backgroundColor: defaultStyles.white50,
    paddingVertical: defaultStyles.padding[1],
    paddingHorizontal: defaultStyles.padding[2],
    borderRadius: 10,
  },
  bubbleHandleStyles: {
    position: 'relative',
    height: 20,
    width: '100%',
  },
});

export default MessageBubble;
