import React from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import avatars from '../../assets/avatars';
import * as defaultStyles from '../../assets/defaultStyles';
import Avatar from '../components/Avatar';
import PrimaryButton from '../components/PrimaryButton';
import { SettingsContext, UserContext } from '../DefaultContainer';

const LoginScreen = () => {
  const [input, setInput] = React.useState('');
  const [selectedAvatarIndex, setSelectedAvatarIndex] = React.useState(
    Math.floor(Math.random() * avatars.length)
  );

  const { setUser, registerUser } = React.useContext(UserContext);
  const { i18n } = React.useContext(SettingsContext);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.mainContainer}
    >
      <ScrollView
        centerContent
        style={styles.avatars}
        contentContainerStyle={{
          flexGrow: Platform.OS === 'android' ? 1 : 0,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {avatars.map((avatar, index) => {
          const selectedStyle = () => {
            if (index === selectedAvatarIndex) {
              return {
                borderColor: defaultStyles.primary,
                borderWidth: 4,
              };
            }
            return {};
          };
          return (
            <TouchableWithoutFeedback
              key={index}
              onPress={() => {
                setSelectedAvatarIndex(index);
              }}
            >
              <View style={[styles.avatarContainer, selectedStyle()]}>
                <Avatar
                  width={85}
                  height={85}
                  avatar={avatar}
                  style={styles.avatarStyle}
                />
              </View>
            </TouchableWithoutFeedback>
          );
        })}
      </ScrollView>
      <TextInput
        style={styles.nickInput}
        onChangeText={(value) => {
          setInput(value.replace(/\s/g, '-'));
        }}
        value={input}
        autoCapitalize='none'
        autoCorrect={false}
        placeholder={i18n.t('nickname')}
        selectionColor={defaultStyles.green}
        textContentType='nickname'
        maxLength={15}
        selectTextOnFocus
      />
      <PrimaryButton
        title={i18n.t('save-profile')}
        onPress={() => {
          registerUser(input, avatars[selectedAvatarIndex]);
        }}
      />
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: defaultStyles.almostWhite,
    height: '100%',
  },
  avatars: {
    alignSelf: 'center',
    maxWidth: 600,
    maxHeight: '70%',
  },
  avatarContainer: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10.0,
    elevation: 6,

    borderRadius: 50,
    overflow: 'hidden',
    margin: defaultStyles.margin[1],
    borderWidth: 4,
    borderColor: defaultStyles.white,
  },
  avatarStyle: {
    backgroundColor: defaultStyles.white,
  },
  nickInput: {
    borderColor: defaultStyles.primary,
    height: 40,
    borderBottomWidth: 3,
    width: '80%',
    fontFamily: 'crete-round-regular',
    fontSize: defaultStyles.fontSize[2],
    paddingHorizontal: defaultStyles.padding[1],
    marginVertical: defaultStyles.margin[3],
    color: defaultStyles.primary,
    maxWidth: 500,
  },
});

export default LoginScreen;
