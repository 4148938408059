import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import * as defaultStyles from '../assets/defaultStyles';
import BelotDrawer from './components/BelotDrawer';
import { SettingsContext, UserContext } from './DefaultContainer';
import AboutScreen from './screens/About/AboutScreen';
import LoginScreen from './screens/LoginScreen';
import MultiScreen from './screens/MultiScreen';
import ProfileUpdateScreen from './screens/Settings/ProfileUpdateScreen';
import SettingsScreen from './screens/Settings/SettingsScreen';
import { sendBasicUserInfo } from './utils/Analytics';

const HomeStack = createStackNavigator();
const LoginStack = createStackNavigator();
const SettingsStack = createStackNavigator();

const Drawer = createDrawerNavigator();

const Login = () => {
  return (
    <LoginStack.Navigator>
      <LoginStack.Screen
        name='Login'
        component={LoginScreen}
        options={{ headerShown: false }}
      />
    </LoginStack.Navigator>
  );
};

const Home = () => {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name='Home'
        component={MultiScreen}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name='ProfileUpdate'
        component={ProfileUpdateScreen}
        options={{ headerShown: false }}
      />
    </HomeStack.Navigator>
  );
};

const Settings = () => {
  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen
        name='Settings'
        component={SettingsScreen}
        options={{ headerShown: false }}
      />
      <SettingsStack.Screen
        name='ProfileUpdate'
        component={ProfileUpdateScreen}
        options={{ headerShown: false }}
      />
    </SettingsStack.Navigator>
  );
};

const Routes = () => {
  const { i18n, settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(UserContext);

  useEffect(() => {
    if (user && settings) {
      sendBasicUserInfo(user, settings);
    }
  }, [user, settings]);

  return (
    <NavigationContainer>
      {user && user.id ? (
        <Drawer.Navigator
          initialRouteName='Home'
          drawerType='back'
          hideStatusBar={Platform.OS === 'android' ? false : true}
          drawerContent={(props) => <BelotDrawer {...props} />}
          drawerPosition='right'
          drawerContentOptions={{
            title: 'Bela',
            activeTintColor: defaultStyles.primary,
            labelStyle: {
              fontFamily: 'crete-round-regular',
              fontSize: defaultStyles.fontSize[0],
            },
          }}
        >
          <Drawer.Screen
            name='Home'
            component={Home}
            options={{ drawerLabel: i18n.t('home') }}
          />
          <Drawer.Screen
            name='Settings'
            component={Settings}
            options={{ drawerLabel: i18n.t('settings') }}
          />
          <Drawer.Screen
            name='About'
            component={AboutScreen}
            options={{ drawerLabel: i18n.t('community') }}
          />
        </Drawer.Navigator>
      ) : (
        <Login />
      )}
    </NavigationContainer>
  );
};

export default Routes;
