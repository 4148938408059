const modern = {
  name: 'modern',
  C_K: {
    img: require('./C_K.png'),
  },
  C_Q: {
    img: require('./C_Q.png'),
  },
  C_J: {
    img: require('./C_J.png'),
  },
  C_A: {
    img: require('./C_A.png'),
  },
  C_7: {
    img: require('./C_7.png'),
  },
  C_8: {
    img: require('./C_8.png'),
  },
  C_9: {
    img: require('./C_9.png'),
  },
  C_10: {
    img: require('./C_10.png'),
  },
  D_A: {
    img: require('./D_A.png'),
  },
  D_K: {
    img: require('./D_K.png'),
  },
  D_Q: {
    img: require('./D_Q.png'),
  },
  D_J: {
    img: require('./D_J.png'),
  },
  D_7: {
    img: require('./D_7.png'),
  },
  D_8: {
    img: require('./D_8.png'),
  },
  D_9: {
    img: require('./D_9.png'),
  },
  D_10: {
    img: require('./D_10.png'),
  },
  H_A: {
    img: require('./H_A.png'),
  },
  H_K: {
    img: require('./H_K.png'),
  },
  H_Q: {
    img: require('./H_Q.png'),
  },
  H_J: {
    img: require('./H_J.png'),
  },
  H_7: {
    img: require('./H_7.png'),
  },
  H_8: {
    img: require('./H_8.png'),
  },
  H_9: {
    img: require('./H_9.png'),
  },
  H_10: {
    img: require('./H_10.png'),
  },
  S_K: {
    img: require('./S_K.png'),
  },
  S_A: {
    img: require('./S_A.png'),
  },
  S_Q: {
    img: require('./S_Q.png'),
  },
  S_J: {
    img: require('./S_J.png'),
  },
  S_7: {
    img: require('./S_7.png'),
  },
  S_8: {
    img: require('./S_8.png'),
  },
  S_9: {
    img: require('./S_9.png'),
  },
  S_10: {
    img: require('./S_10.png'),
  },
  back: {
    img: require('./back3.png'),
  },
  blank: {
    img: require('./blank.png'),
  },
};

export default modern;
