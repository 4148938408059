import React, { useContext, useRef } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import { SettingsContext } from '../../DefaultContainer';
import * as defaultStyles from '../../../assets/defaultStyles';
import { Feather } from '@expo/vector-icons';
import { TouchableWithoutFeedback } from 'react-native';
import { Platform } from 'react-native';

function Search({ search, setSearch }) {
  const { i18n } = useContext(SettingsContext);
  const searchRef = useRef();

  return (
    <View style={styles.searchContainer}>
      <TextInput
        ref={searchRef}
        style={styles.searchInput}
        onChangeText={(value) => {
          setSearch(value.replace(/\s/g, '-'));
        }}
        value={search}
        autoCapitalize='none'
        autoCorrect={false}
        keyboardType='default'
        placeholder={i18n.t('enter-room-name')}
        selectionColor={defaultStyles.green}
        textContentType='nickname'
      />
      <TouchableWithoutFeedback
        onPress={() => {
          searchRef.current.clear();
          searchRef.current.focus();
        }}
      >
        <Feather
          style={styles.searchIcon}
          name={search ? 'x' : 'search'}
          size={24}
          color={defaultStyles.primary30}
        />
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
  searchContainer: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: defaultStyles.padding[3],
    justifyContent: 'space-between',
    backgroundColor: defaultStyles.white,
    borderRadius: 6,
    width: '90%',

    color: defaultStyles.primary,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10.0,

    elevation: 6,
  },
  searchInput: {
    paddingVertical: defaultStyles.padding[3],
    fontFamily: 'crete-round-regular',
    fontSize: defaultStyles.fontSize[1],
    flex: 1,
  },
  searchIcon: {},
});

export default Search;
