import io from 'socket.io-client';
import { SERVER_URL } from '../config';

const INITIALIZE_CONNECTION_ACTION = 'initializeConnection';
const CREATE_ROOM_ACTION = 'createRoom';
const JOIN_ROOM_ACTION = 'joinRoom';
const LEAVE_ROOM_ACTION = 'leaveRoom';
const START_ACTION = 'start';
const THROW_ACTION = 'throw';
const DECLARE_ACTION = 'declare';
const SET_TRUMP_ACTION = 'setTrump';
const PASS_TRUMP_ACTION = 'passTrump';
const ACCEPT_DECLARATIONS = 'acceptDeclarations';
const SEND_REACTION_ACTION = 'sendReaction';
const IS_ONLINE_ACTION = 'isOnline';
const READY_TO_PLAY_ACTION = 'readyToPlay';
const CHANGE_ROOM_PRIVACY = "changeRoomPrivacy"

const actions = [
  INITIALIZE_CONNECTION_ACTION,
  CREATE_ROOM_ACTION,
  JOIN_ROOM_ACTION,
  LEAVE_ROOM_ACTION,
  START_ACTION,
  PASS_TRUMP_ACTION,
  SET_TRUMP_ACTION,
  DECLARE_ACTION,
  ACCEPT_DECLARATIONS,
  THROW_ACTION,
  SEND_REACTION_ACTION,
  CHANGE_ROOM_PRIVACY,
];

const SEND_IS_ONLINE_INTERVAL = 10000;

class Connection {
  constructor(id, setGame, roomId, setLatestReaction, setServerMessage) {
    this.socket = io(`${SERVER_URL}/?hash=${roomId}`, {
      transports: ['websocket'],
    });
    this.id = id;

    this.socket.on('connect', () => {
      console.info('Connected...');
    });

    this.socket.on('disconnect', () => {
      console.info('Disconnected...');
    });

    this.socket.on('reconnect', () => {
      console.info('Reconnected...');
      this.initialize(id);
    });

    this.socket.on('reconnect_attempt', () => {
      this.socket.io.opts.transports = ['websocket', 'polling'];
      console.warn('Reconnect attempt...');
    });

    this.socket.on('stateUpdated', (msg) => {
      // console.info('stateUpdated', msg);
      setGame(msg);
    });

    this.socket.on('message', (msg) => {
      if (msg.message === 'ERROR_NOT_REGISTERED') {
        console.warn('Need to register initialize...');
        this.initialize(id);
        return;
      }
      setServerMessage(msg);
    });

    this.socket.on('reaction', (msg) => {
      setLatestReaction({
        [msg.sender.id]: { reactionName: msg.message, time: Date.now() },
      });
    });

    this.initialize(id);
  }

  initialize(id) {
    if (id) {
      this.sendAction(INITIALIZE_CONNECTION_ACTION, { id });
      // Init sending is online ping
      if (typeof this.sendIsOnlineInterval !== 'undefined') {
        clearInterval(this.sendIsOnlineInterval);
      }
      this.sendIsOnlineInterval = setInterval(
        () => this.sendIsOnline(true),
        SEND_IS_ONLINE_INTERVAL
      );
    }
  }

  sendAction(action, data) {
    this.socket.emit('action', {
      action,
      data,
    });
  }

  cardPressed(card, gameState) {
    if (!gameState) {
      return;
    }
    if (gameState === 'DECLARING') {
      this.toggleDeclareCard(card);
    } else if (gameState === 'THROWING') {
      this.throwCard(card);
    }
  }
  toggleDeclareCard(card) {
    if (this.declarations.includes(card)) {
      this.declarations = this.declarations.filter((item) => item !== card);
      return;
    }
    this.declarations.push(card);
  }
  throwCard(card) {
    if (!card) {
      return;
    }
    this.sendAction(THROW_ACTION, card);
  }
  sendAllDeclaration(hand) {
    const declarations = hand.map((card) => card.str);
    this.sendDeclaration(declarations);
  }
  sendDeclaration(declarations) {
    this.sendAction(DECLARE_ACTION, declarations.join(','));
  }
  acceptDeclarations() {
    this.sendAction(ACCEPT_DECLARATIONS, '');
  }
  setTrump(suit) {
    this.sendAction(SET_TRUMP_ACTION, suit);
  }
  passTrump(suit) {
    this.sendAction(PASS_TRUMP_ACTION, '');
  }
  leaveRoom() {
    this.sendAction(LEAVE_ROOM_ACTION, '');
  }
  disconnect() {
    this.sendIsOnline(false);
    this.socket.disconnect();
    clearInterval(this.sendIsOnlineInterval);
  }
  sendIsOnline(isOnline) {
    this.sendAction(IS_ONLINE_ACTION, { isOnline });
  }
  sendReadyToPlay() {
    this.sendAction(READY_TO_PLAY_ACTION, {});
  }
  setRoomPrivacy(isPrivate) {
    this.sendAction(CHANGE_ROOM_PRIVACY, { isPrivate });
  }
  register(name) {
    if (name) {
      // this.$toasted.show('Unesi kak se zoveš kompiću', {
      //   position: 'bottom-center',
      //   duration: 2000,
      // });
      return;
    }
    this.sendAction(REGISTER_ACTION, { name, id: this.id });
  }

  createRoom(roomId) {
    if (!roomId) {
      // this.$toasted.show('Unesi kak se zove soba', {
      //   position: 'bottom-center',
      //   duration: 2000,
      // });
      return;
    }

    this.sendAction(CREATE_ROOM_ACTION, roomId);
  }
  joinRoom(roomId) {
    if (!roomId) {
      // this.$toasted.show('Unesi kak se zove soba', {
      //   position: 'bottom-center',
      //   duration: 2000,
      // });
      return;
    }
    this.sendAction(JOIN_ROOM_ACTION, roomId);
  }
  start() {
    this.sendAction(START_ACTION);
  }
  sendReaction(reaction) {
    this.sendAction(SEND_REACTION_ACTION, reaction);
  }
}

export default Connection;
