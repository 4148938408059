import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import * as defaultStyles from '../../assets/defaultStyles';
import CreteRoundText from './text/CreteRoundText';

const PrimaryButton = (props) => {
  const {
    title,
    textStyle,
    style,
    icon,
    tint = defaultStyles.primary,
    disabled,
  } = props;
  return (
    <TouchableOpacity
      {...props}
      style={[
        styles.container,
        style,
        { backgroundColor: tint },
        { opacity: disabled ? 0.5 : 1 },
      ]}
    >
      <CreteRoundText style={[styles.title, textStyle]}>{title}</CreteRoundText>
      {icon}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: defaultStyles.primary,
    paddingVertical: defaultStyles.padding[3],
    paddingHorizontal: defaultStyles.padding[5],
    borderRadius: 6,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,

    elevation: 6,
  },
  title: {
    color: defaultStyles.white,
    fontSize: defaultStyles.fontSize[1],
  },
});

export default PrimaryButton;
