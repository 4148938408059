import Constants from 'expo-constants';
import React, { useEffect, useRef, useState } from 'react';
import { AppState } from 'react-native';
import { modern } from '../assets/decks';
import { http } from './http';
import i18n, { setLocale } from './localization';
import Connection from './utils/Connection';
import {
  loadSettings,
  loadUser,
  saveSettings,
  saveUser,
} from './utils/Storage';

export const GameContext = React.createContext({
  game: null,
  setGame: () => {},
  connection: null,
  reactions: null,
  setReactions: () => {},
});

export const UserContext = React.createContext({
  user: null,
  setUser: () => {},
});

export const SettingsContext = React.createContext({
  settings: null,
  setSettings: () => {},
  i18n: null,
});

let timeoutId = null;

const DefaultContainer = (props) => {
  const [game, setGame] = useState(null);
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [connection, setConnection] = useState(null);
  const [latestReaction, setLatestReaction] = useState(null);
  const [reactions, setReactions] = useState({});
  const [serverMessage, setServerMessage] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const appState = useRef(AppState.currentState);

  useEffect(() => {
    AppState.addEventListener('change', _handleAppStateChange);

    return () => {
      AppState.removeEventListener('change', _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    let online = true;
    if (nextAppState !== 'active') {
      online = false;
    }

    if (connection) {
      connection.sendIsOnline(online);
    }

    appState.current = nextAppState;
  };

  const setServerMessageForInterval = (message) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setServerMessage(message);
    timeoutId = setTimeout(() => {
      setServerMessage(null);
      timeoutId = null;
    }, 3000);
  };

  const removeReactionFromPlayer = (id) => {
    setReactions({ ...reactions, [id]: null });
  };

  useEffect(() => {
    setReactions({ ...reactions, ...latestReaction });
  }, [latestReaction]);

  function destroyConnection() {
    if (!connection) {
      return;
    }
    connection.disconnect();
    setConnection(null);
    loadMe();
  }

  function initializeConnection(roomId) {
    if (user && user.id && !connection) {
      setConnection(
        new Connection(
          user.id,
          setGame,
          roomId,
          setLatestReaction,
          setServerMessageForInterval
        )
      );
    }
  }

  const initializeSettings = (storageSettings) => {
    if (!storageSettings) {
      setSettings({
        lang: 'hr',
        cards: modern,
        cardsChosen: false,
        haptics: true,
      });
      return;
    }

    if (storageSettings.lang) {
      setLocale(storageSettings.lang);
    }
    if (!storageSettings.cardsChosen) {
      setSettings({ ...storageSettings, cards: modern, cardsChosen: false });
    } else {
      setSettings(storageSettings);
    }

    if (storageSettings.haptics === undefined) {
      setSettings({ ...storageSettings, haptics: true });
    }
  };

  const changeCards = (cardType) => {
    if (cardType) {
      setSettings({ ...settings, cards: cardType, cardsChosen: true });
    } else {
      setSettings({ ...settings, cards: modern, cardsChosen: true });
    }
  };

  const setDefaultCards = () => {
    setSettings({ ...settings, cardsChosen: true });
  };

  const initializeUser = (storageUser) => {
    if (!storageUser) {
      setUser({
        id: '',
        nick: '',
        avatar: null,
      });
      setConnection(null);
      return;
    }
    setUser(storageUser);
  };

  const loadMe = async () => {
    const response = await http.post('/me', {
      id: user.id,
    });
    setGame(response.data);
  };

  const loadInitialGameState = async () => {
    if (user && user.id) {
      const response = await http.post('/me', {
        id: user.id,
      });

      if (!connection && response.data.roomId) {
        initializeConnection(response.data.roomId);
      }
    }
  };

  const registerUser = async (nick, avatar) => {
    try {
      const response = await http.post('/register', {
        name: nick,
        avatar: avatar.name,
      });
      const id = response.data.id;
      setUser({
        nick,
        avatar,
        id,
        appVersion: Constants.manifest.version,
      });
      loadInitialGameState();
    } catch (e) {
      console.warn(e);
    }
  };

  const updateUser = async (nick, avatar) => {
    try {
      await http.post('/user', {
        name: nick,
        id: user.id,
        avatar: avatar.name,
      });
    } catch (e) {
      console.warn(e);
    }

    setUser({
      ...user,
      nick,
      avatar,
    });
  };

  const updateNickname = (newNick) => {
    setUser({ ...user, nick: newNick });
  };

  const updateAvatar = (avatar) => {
    setUser({ ...user, avatar });
  };

  const setLang = (lang) => {
    setLocale(lang);
    setSettings({ ...settings, lang });
  };

  const setHaptics = (haptics) => {
    setSettings({ ...settings, haptics });
  };

  useEffect(() => {
    saveUser(user);
    loadInitialGameState();
    return () => {};
  }, [user]);

  useEffect(() => {
    saveSettings(settings);
    return () => {};
  }, [settings]);

  useEffect(() => {
    loadSettings(initializeSettings);
    loadUser(initializeUser);
    return () => {};
  }, []);

  const loadRooms = async () => {
    try {
      if (game && game.roomId) {
        return;
      }
      const response = await http.get('/rooms');
      setRooms(response.data.rooms);
    } catch (e) {}
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      loadRooms();
    }, 2000);
    // First room load, show loader
    setIsLoading(true);
    loadRooms().then(() => setIsLoading(false));
    return () => clearInterval(interval);
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        i18n,
        setLang,
        setHaptics,
        changeCards,
        setDefaultCards,
      }}
    >
      <UserContext.Provider
        value={{
          user,
          setUser,
          updateUser,
          updateNickname,
          updateAvatar,
          registerUser,
          initializeUser,
        }}
      >
        <GameContext.Provider
          value={{
            game,
            connection,
            reactions,
            serverMessage,
            setServerMessageForInterval,
            initializeConnection,
            destroyConnection,
            removeReactionFromPlayer,
            rooms,
            setRooms,
            isLoading,
            setIsLoading,
          }}
        >
          {props.children}
        </GameContext.Provider>
      </UserContext.Provider>
    </SettingsContext.Provider>
  );
};

export default DefaultContainer;
