import { Feather } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import * as defaultStyles from '../../../assets/defaultStyles';
import AllCardStyles from '../../components/AllCardStyles';
import Popup from '../../components/Popup';
import ArchivoText from '../../components/text/ArchivoText';
import CreteRoundText from '../../components/text/CreteRoundText';
import TopBar from '../../components/TopBar';
import { SettingsContext, UserContext } from '../../DefaultContainer';
import Toggle from '../../components/primitives/Toggle';
import * as Haptics from 'expo-haptics';
import { Platform } from 'react-native';

const SettingsScreen = ({ navigation }) => {
  const { settings, setLang, i18n, changeCards, setHaptics } = React.useContext(
    SettingsContext
  );
  const { initializeUser } = React.useContext(UserContext);

  const [selected, setSelected] = useState({
    lang: settings.lang,
    haptics: settings.haptics,
  });
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  const setSelectedByKey = (key, value) => {
    setSelected({ ...selected, [key]: value });
  };

  const selectedLanguageStyle = (key, value) => {
    if (
      selected[key] === value ||
      (key === 'lang' &&
        selected[key] &&
        selected[key].substring(0, 2) === value)
    ) {
      return {
        backgroundColor: defaultStyles.primary30,
        borderRadius: 6,
      };
    }
  };

  const selectedCardsStyle = (cardStyle) => {
    if (settings.cards === cardStyle) {
      return {
        backgroundColor: defaultStyles.primary30,
        borderRadius: 6,
      };
    }
  };

  useEffect(() => {
    if (settings.haptics && Platform.OS === 'ios') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
  }, [settings.haptics]);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <TopBar navigation={navigation} />
      <ArchivoText bold style={styles.settingsTitle}>
        {i18n.t('settings')}
      </ArchivoText>
      <ScrollView style={{ width: '100%' }}>
        <View style={styles.setting}>
          <TouchableOpacity
            style={styles.settingCategory}
            onPress={() => {
              navigation.navigate('ProfileUpdate');
            }}
          >
            <CreteRoundText style={styles.settingHeader}>
              {i18n.t('edit-profile')}
            </CreteRoundText>
            <Feather name='chevron-right' size={35} />
          </TouchableOpacity>
        </View>
        <View style={styles.setting}>
          <CreteRoundText style={styles.settingHeader}>
            {i18n.t('language')}
          </CreteRoundText>
          <View style={styles.settingOptionsContainer}>
            {[
              { value: 'hr', label: '🇭🇷' },
              { value: 'en', label: '🇺🇸' },
            ].map((item) => (
              <TouchableWithoutFeedback
                key={item.value}
                onPress={() => {
                  setSelectedByKey('lang', item.value), setLang(item.value);
                }}
              >
                <View
                  style={[
                    styles.settingsButton,
                    selectedLanguageStyle('lang', item.value),
                  ]}
                >
                  <ArchivoText style={styles.flag}>{item.label}</ArchivoText>
                </View>
              </TouchableWithoutFeedback>
            ))}
          </View>
        </View>
        {Platform.OS === 'ios' && (
          <TouchableWithoutFeedback
            onPress={() => {
              setHaptics(!settings.haptics);
            }}
          >
            <View style={styles.setting}>
              <CreteRoundText style={styles.settingHeader}>
                {i18n.t('vibration-setting')}
              </CreteRoundText>
              <View style={styles.settingOptionsContainer}>
                <Toggle
                  enabled={settings.haptics}
                  onPress={() => setHaptics(!settings.haptics)}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        )}
        <View style={styles.chooseCards}>
          <CreteRoundText
            style={[
              styles.settingHeader,
              { marginBottom: defaultStyles.margin[3] },
            ]}
          >
            {i18n.t('choose-card-type')}
          </CreteRoundText>
          <AllCardStyles />
        </View>
        <TouchableOpacity
          style={styles.logoutButton}
          onPress={() => {
            setShowLogoutPopup(true);
          }}
        >
          <CreteRoundText
            style={{
              fontSize: defaultStyles.fontSize[1],
              color: defaultStyles.red,
            }}
          >
            {i18n.t('log-out')}
          </CreteRoundText>
        </TouchableOpacity>
      </ScrollView>
      {showLogoutPopup && (
        <Popup
          title={i18n.t('log-out')}
          description={i18n.t('log-out-popup-description')}
          hidePopup={() => setShowLogoutPopup(false)}
          options={[
            { value: i18n.t('cancel') },
            {
              value: i18n.t('log-out-confirm'),
              color: defaultStyles.red,
              action: () => {
                initializeUser();
              },
            },
          ]}
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },
  settingsTitle: {
    alignSelf: 'center',
    fontSize: defaultStyles.fontSize[6],
    marginBottom: defaultStyles.margin[2],
    width: '90%',
    maxWidth: 700,
  },
  settingCategory: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  setting: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: defaultStyles.margin[2],
    maxWidth: 700,
    width: '90%',
  },
  settingHeader: {
    fontSize: defaultStyles.fontSize[2],
  },
  settingOptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
    justifyContent: 'flex-end',
  },
  settingsButton: {
    paddingHorizontal: defaultStyles.padding[2],
    marginLeft: defaultStyles.margin[1],
  },
  flag: {
    fontSize: defaultStyles.fontSize[4],
  },
  chooseCards: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    maxWidth: 700,
    marginVertical: defaultStyles.margin[2],
  },
  logoutButton: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 700,
    padding: defaultStyles.padding[4],
  },
});

export default SettingsScreen;
