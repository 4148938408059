import React, { useState, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import * as ds from '../../../assets/defaultStyles';
import Avatar from '../../components/Avatar';
import CreteRoundText from '../../components/text/CreteRoundText';
import { http } from '../../http';

function UserDetails({ navigation, user }) {
  const [winRate, setWinRate] = useState(null);

  const getWinRate = async () => {
    try {
      const response = await http.post('/win-rate', { id: user.id });
      setWinRate(response.data);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    getWinRate();
  }, []);

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        navigation.navigate('ProfileUpdate');
      }}
    >
      <View style={styles.avatarContainer}>
        <Avatar
          width={55}
          height={55}
          style={styles.avatar}
          avatar={user.avatar}
          isOnline={user.isOnline}
        />
      </View>
      <View style={styles.userInfoContaier}>
        <CreteRoundText primary style={styles.nick}>
          {user.nick}
        </CreteRoundText>
        {winRate && (
          <CreteRoundText primary style={{ fontSize: ds.fontSize[0] }} italic>
            {winRate.wins}/{winRate.total} ({Math.floor(winRate.winRate * 100)/100}%)
          </CreteRoundText>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '90%',
    maxWidth: 700,
  },
  avatarContainer: {
    borderRadius: 50,
    overflow: 'hidden',
    margin: ds.margin[1],
    backgroundColor: ds.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10.0,

    elevation: 6,

    borderWidth: 4,
    borderColor: ds.primary,
  },
  nick: {
    fontSize: ds.fontSize[2],
    flexShrink: 1,
  },
  userInfoContaier: {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: ds.padding[3],
  },
});

export default UserDetails;
