import React, { useContext } from 'react';

import Popup from '../../components/Popup';
import { SettingsContext } from '../../DefaultContainer';

function CreateRoomPopup({ onHide, onCreateGame, isLoading }) {
  const { i18n } = useContext(SettingsContext);

  return (
    <Popup
      title={i18n.t('create-game')}
      description={i18n.t('create-game-description')}
      hidePopup={onHide}
      options={[
        {
          value: '501',
          action: async () => {
            await onCreateGame(501);
          },
          disabled: isLoading,
        },
        {
          value: '701',
          action: async () => {
            await onCreateGame(701);
          },
          disabled: isLoading,
        },
        {
          value: '1001',
          action: async () => {
            await onCreateGame(1001);
          },
          disabled: isLoading,
        },
      ]}
    />
  );
}

export default CreateRoomPopup;
