import React from 'react';
import { Image } from 'react-native';

const Avatar = ({ avatar, width = 100, height = 100, style, borderRadius }) => {
  return (
    <Image
      source={avatar.img}
      style={[{ width, height }, style]}
      fadeDuration={0}
      resizeMode='contain'
      borderRadius={borderRadius}
    />
  );
};

export default Avatar;
