const modernSuits = {
  C: {
    img: require('./C.png'),
  },
  S: {
    img: require('./S.png'),
  },
  D: {
    img: require('./D.png'),
  },
  H: {
    img: require('./H.png'),
  },
};

export default modernSuits;
