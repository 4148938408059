import React, { useContext } from 'react';
import { View, StyleSheet, Platform, Share } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import ArchivoText from '../../components/text/ArchivoText';
import { SettingsContext } from '../../DefaultContainer';
import * as ds from '../../../assets/defaultStyles';
import AboutRow from './AboutRow';
import TopBar from '../../components/TopBar';
import { ScrollView } from 'react-native-gesture-handler';
import PrimaryButton from '../../components/PrimaryButton';

interface AboutScreenProps {
  navigation: any;
}

const AboutScreen = ({ navigation }: AboutScreenProps) => {
  const { i18n } = useContext(SettingsContext);

  const platformSpecific = (): { icon: string; url: string } => {
    switch (Platform.OS) {
      case 'ios':
        return { icon: 'apple', url: 'https://app.bela.fun/bela-app-blok-ios' };
      case 'android':
        return {
          icon: 'google-play',
          url: 'https://app.bela.fun/bela-app-blok-android',
        };
      default:
        return {
          icon: 'book-open',
          url: 'https://app.bela.fun/app-bela-blok-web',
        };
    }
  };

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: i18n.t('share-app-text'),
      });
      if (result.action === Share.sharedAction) {
        console.log('user shared');
      } else if (result.action === Share.dismissedAction) {
        console.log('user dismissed');
      }
    } catch (e) {
      console.warn(e.message);
    }
  };

  return (
    <SafeAreaView>
      <TopBar navigation={navigation} />
      <View style={styles.container}>
        <ArchivoText bold style={styles.screenTitle}>
          {i18n.t('community')}
        </ArchivoText>
        <ScrollView style={{ height: '100%' }}>
          <AboutRow
            url='https://app.bela.fun/bela-app-instagram'
            title={i18n.t('instagram')}
            description={i18n.t('instagram-description')}
            icon='instagram'
            iconColor='#BA2971'
          />
          <AboutRow
            url='https://app.bela.fun/bela-app-reddit'
            title={i18n.t('subreddit')}
            description={i18n.t('subreddit-description')}
            icon='reddit'
            iconColor='#EC5428'
          />
          <AboutRow
            url={platformSpecific().url}
            title={i18n.t('bela-blok')}
            description={i18n.t('bela-blok-description')}
            icon={platformSpecific().icon}
          />
          <AboutRow
            url='mailto:belaonline@sombrerito.co'
            title={i18n.t('email')}
            description={i18n.t('email-description')}
            icon='email-outline'
          />
          <PrimaryButton
            title={i18n.t('share-app')}
            style={{ marginTop: ds.margin[3] }}
            onPress={onShare}
          />
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    display: 'flex',
    width: '90%',
    maxWidth: 700,
  },
  screenTitle: {
    fontSize: ds.fontSize[6],
    paddingBottom: ds.padding[3],
  },
});

export default AboutScreen;
