import React from 'react';
import { Image, StyleSheet } from 'react-native';

const Card = ({
  width = 50,
  height = 70,
  card,
  style,
  scale = 1,
  hidden = false,
}) => {
  const scaledWidth = width * scale;
  const scaledHeight = height * scale;

  return (
    <Image
      source={card.img}
      style={[
        {
          width: scaledWidth,
          height: scaledHeight,
          opacity: hidden ? 0 : 1,
        },
        style,
      ]}
      fadeDuration={0}
      resizeMode='contain'
    />
  );
};

const styles = StyleSheet.create({});

export default Card;
