import React from 'react';
import {
  View,
  TouchableOpacity,
  Linking,
  StyleSheet,
} from 'react-native';
import * as ds from '../../../assets/defaultStyles';
import ArchivoText from '../../components/text/ArchivoText';
import CreteRoundText from '../../components/text/CreteRoundText';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface AboutRowProps {
  url: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  icon?: string | React.ReactNode;
  iconColor?: string;
}

const AboutRow = ({
  url,
  title,
  description,
  icon,
  iconColor = ds.primary,
}: AboutRowProps) => {
  const openUrl = () => {
    Linking.openURL(url).catch(() => {});
  };

  return (
    <TouchableOpacity style={styles.container} onPress={openUrl}>
      <View style={styles.row}>
        {icon && typeof icon === 'string' ? (
          <MaterialCommunityIcons
            name={icon}
            size={ds.fontSize[5]}
            style={{ paddingRight: ds.padding[3] }}
            color={iconColor}
          />
        ) : (
          icon
        )}
        <View style={{ flex: 1 }}>
          <ArchivoText style={{ fontSize: ds.fontSize[1] }} primary bold>
            {title}
          </ArchivoText>
          <CreteRoundText
            style={{
              flexWrap: 'wrap',
            }}
            primary
          >
            {description}
          </CreteRoundText>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    borderRadius: 4,
    paddingVertical: ds.padding[3],
  },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
});

export default AboutRow;
