import React, { useContext, useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import avatars from '../../assets/avatars';
import * as defaultStyles from '../../assets/defaultStyles';
import Avatar from '../components/Avatar';
import { SettingsContext } from '../DefaultContainer';
import IsOnlineBadge from './IsOnlineBadge';
import PrimaryButton from './PrimaryButton';
import ArchivoText from './text/ArchivoText';
import CreteRoundText from './text/CreteRoundText';

const PlayerCard = (props) => {
  const {
    name,
    highlight = false,
    avatarName,
    playerId,
    isBot,
    teamId,
    isOnline,
  } = props;

  const [avatarImage, setAvatarImage] = useState(null);

  const { i18n } = useContext(SettingsContext);

  useEffect(() => {
    if (!avatarName) setAvatarImage(null);
    setAvatarImage(avatars.find((avatar) => avatar.name === avatarName));
  }, [avatarName]);

  const hasNameStyle = () => {
    if (name) {
      return {
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.1,
        shadowRadius: 8,

        elevation: 12,
        borderColor: highlight ? defaultStyles.primary : defaultStyles.white,
      };
    } else {
      return {};
    }
  };

  return (
    <Animatable.View
      animation={avatarName ? 'pulse' : ''}
      easing='ease-out'
      style={[styles.container, hasNameStyle(), props.style]}
    >
      <View>
        {isOnline && (
          <IsOnlineBadge
            style={{ zIndex: 1, position: 'absolute', top: 0, right: 15 }}
          />
        )}
        {avatarImage ? (
          <View style={styles.avatarContainer}>
            <Avatar
              avatar={avatarImage}
              width={40}
              height={40}
              borderRadius={20}
              style={{
                backgroundColor: defaultStyles.white,
              }}
            />
          </View>
        ) : (
          <View></View>
        )}
      </View>
      <View style={styles.nameButton}>
        <CreteRoundText
          style={[
            styles.text,
            {
              opacity: name ? 1 : 0.2,
            },
          ]}
        >
          {name ? name : i18n.t('waiting') + '...'}
        </CreteRoundText>

        {!name && (
          <PrimaryButton
            title={i18n.t('add-bot')}
            style={styles.primaryButton}
            tint={defaultStyles.primaryLight}
            onPress={() => {
              props.onAddBot(teamId);
            }}
          />
        )}
        {isBot && (
          <PrimaryButton
            title={i18n.t('kick-bot')}
            style={styles.primaryButton}
            tint={defaultStyles.red}
            onPress={() => props.onKickBot(playerId)}
          />
        )}
      </View>
    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: defaultStyles.padding[4],
    paddingVertical: defaultStyles.padding[4],
    borderColor: defaultStyles.white,
    borderRadius: 6,
    borderWidth: 2,
    backgroundColor: defaultStyles.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  avatarContainer: {
    borderRadius: 20,
    backgroundColor: defaultStyles.white,
    shadowColor: defaultStyles.black,
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10.0,
    elevation: 6,

    overflow: Platform.OS === 'ios' ? 'visible' : 'hidden',
    width: 40,
    height: 40,
    marginRight: defaultStyles.margin[3],
    borderRadius: 25,
  },
  text: {
    fontSize: defaultStyles.fontSize[1],
  },
  primaryButton: {
    paddingVertical: defaultStyles.padding[1],
    paddingHorizontal: defaultStyles.padding[3],
  },
  nameButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
});

export default PlayerCard;
