import React from "react";
import { View, ViewStyle, StyleSheet } from "react-native";
import * as ds from "../../assets/defaultStyles";
import ArchivoText from "./text/ArchivoText";

interface DealerChipProps {
  scale: number;
  containerSize: number;
  fontSize: number;
  style: ViewStyle;
}

const DealerChip = ({
  style,
  scale = 1,
  containerSize = 20,
  fontSize = 10,
}: DealerChipProps) => {
  return (
    <View
      style={[
        styles.container,
        {
          width: scale * containerSize,
          height: scale * containerSize,
          borderRadius: (scale * containerSize) / 2,
        },
        style,
      ]}
    >
      <ArchivoText
        bold
        style={[styles.chipText, { fontSize: fontSize * scale }]}
      >
        D
      </ArchivoText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    borderRadius: 10,

    backgroundColor: ds.primary,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,

    elevation: 6,
  },
  chipText: {
    fontSize: 10,
    color: ds.white,
    textAlign: "center",
  },
});

export default DealerChip;
