import { Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useContext, useEffect, useState } from 'react';
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
  TouchableWithoutFeedback,
  Linking,
} from 'react-native';
import * as defaultStyles from '../../../assets/defaultStyles';
import LoadingOverlay from '../../components/LoadingOverlay';
import PlayerCard from '../../components/PlayerCard';
import PrimaryButton from '../../components/PrimaryButton';
import Toggle from '../../components/primitives/Toggle';
import Popup from '../../components/Popup';
import ArchivoText from '../../components/text/ArchivoText';
import CreteRoundText from '../../components/text/CreteRoundText';
import {
  GameContext,
  SettingsContext,
  UserContext,
} from '../../DefaultContainer';
import { http } from '../../http';

const RoomScreen = () => {
  const { game, connection, destroyConnection, serverMessage } = useContext(
    GameContext
  );
  const { user } = useContext(UserContext);
  const { i18n } = useContext(SettingsContext);
  const [roomScorePopup, setRoomScorePopup] = useState(false);
  const [roomScorePopupShown, setRoomScorePopupShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDonateCTA] = useState(Math.random() <= 0.05);
  const [lastGameResults, setLastGameResults] = useState(undefined);

  const addBot = async (teamId) => {
    const response = await http.post(`/addBot?hash=${game.roomId}`, {
      id: user.id,
      roomId: game.roomId,
      teamId,
    });
  };

  const kickBot = async (id) => {
    const response = await http.post(`/kickBot?hash=${game.roomId}`, {
      id,
      roomId: game.roomId,
    });
  };

  const setRoomPrivacy = (value) => {
    connection.setRoomPrivacy(value);
  };

  const weWon = () => {
    if (game && game.gamePoints) {
      if (game.myTeamId === 1) {
        if (game.gamePoints.team1 > game.gamePoints.team2) return true;
        return false;
      }
      if (game.myTeamId === 2) {
        if (game.gamePoints.team1 < game.gamePoints.team2) return true;
        return false;
      }
    }
  };

  useEffect(() => {
    if (game && game.match && game.match.finished && !roomScorePopupShown) {
      setRoomScorePopup(true);
      setRoomScorePopupShown(true);
    }
    if (game && game.gamePoints) {
      setLastGameResults(game.gamePoints);
    }
  }, [game]);

  return (
    <>
      <SafeAreaView>
        {isLoading && <LoadingOverlay />}
        <ScrollView
          centerContent
          style={[
            styles.mainContainer,
            {
              height:
                Platform.OS === 'web' ? useWindowDimensions().height : '100%',
            },
          ]}
          contentContainerStyle={{
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          {game.roomId && (
            <>
              <View style={styles.roomNameContainer}>
                <View>
                  <ArchivoText style={styles.roomNameLabel} italic>
                    {i18n.t('room-name')}
                  </ArchivoText>
                  <ArchivoText style={styles.roomName} bold>
                    {game.roomId}
                  </ArchivoText>
                  <ArchivoText style={styles.roomPassword}>
                    {game.isPrivate
                      ? `${i18n.t('room-password')}: ${game.roomPassword}`
                      : ' '}
                  </ArchivoText>
                </View>
                <View>
                  <TouchableWithoutFeedback
                    onPress={() => {
                      setRoomPrivacy(!game.isPrivate);
                    }}
                  >
                    <View style={styles.roomPrivacyContainer}>
                      <ArchivoText style={styles.roomPrivacyLabel}>
                        {i18n.t('is-room-private')}
                      </ArchivoText>
                      <View style={styles.roomPrivacyToggle}>
                        <Toggle
                          enabled={game.isPrivate}
                          onPress={() => setRoomPrivacy(!game.isPrivate)}
                          height={35}
                          customHead={
                            <View
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '45%',
                                backgroundColor: defaultStyles.almostWhite,
                                borderRadius: 4,
                                margin: defaultStyles.padding[0],
                              }}
                            >
                              <Feather
                                name={game.isPrivate ? 'lock' : 'unlock'}
                                size={defaultStyles.fontSize[0]}
                                color={
                                  game.isPrivate
                                    ? defaultStyles.green
                                    : defaultStyles.primary30
                                }
                              />
                            </View>
                          }
                        />
                      </View>
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              </View>
              {game.team1 &&
                [0, 1].map((index) => {
                  let playerId = undefined;
                  let isBot = false;
                  let name = '';
                  let avatarName = '';
                  let isOnline = false;
                  if (game.team1[index]) {
                    playerId = game.team1[index].id;
                    isBot = game.team1[index].isBot;
                    name = game.team1[index].name;
                    avatarName = game.team1[index].avatar;
                    isOnline = game.team1[index].isOnline;
                  }
                  return (
                    <PlayerCard
                      highlight={name === user.nick}
                      style={styles.playerCard}
                      key={index}
                      index={index}
                      playerId={playerId}
                      isBot={isBot}
                      name={name}
                      avatarName={avatarName}
                      teamId={1}
                      isOnline={isOnline}
                      onAddBot={addBot}
                      onKickBot={kickBot}
                    />
                  );
                })}
              <CreteRoundText
                italic
                style={{
                  fontSize: defaultStyles.fontSize[0],
                }}
              >
                vs
              </CreteRoundText>
              {game.team2 &&
                [0, 1].map((index) => {
                  let playerId = undefined;
                  let isBot = false;
                  let name = '';
                  let avatarName = '';
                  let isOnline = false;
                  if (game.team2[index]) {
                    playerId = game.team2[index].id;
                    isBot = game.team2[index].isBot;
                    name = game.team2[index].name;
                    avatarName = game.team2[index].avatar;
                    isOnline = game.team2[index].isOnline;
                  }
                  return (
                    <PlayerCard
                      highlight={name === user.nick}
                      style={styles.playerCard}
                      key={index}
                      playerId={playerId}
                      isBot={isBot}
                      name={name}
                      avatarName={avatarName}
                      onAddBot={addBot}
                      onKickBot={kickBot}
                      teamId={2}
                      isOnline={isOnline}
                      index={index}
                    />
                  );
                })}
              <View style={styles.actionButtonsContainer}>
                <TouchableOpacity
                  onPress={async () => {
                    setIsLoading(true);
                    try {
                      await http.post(`/leaveRoom?hash=${game.roomId}`, {
                        id: user.id,
                      });
                      destroyConnection();
                    } catch (e) {
                      console.warn(e);
                    }
                    setTimeout(() => setIsLoading(false), 500);
                  }}
                  style={styles.secondaryButton}
                >
                  <CreteRoundText
                    style={{
                      color: defaultStyles.primary,
                      fontSize: defaultStyles.fontSize[0],
                    }}
                  >
                    {i18n.t('leave-room')}
                  </CreteRoundText>
                </TouchableOpacity>
                <PrimaryButton
                  title={i18n.t('start-game')}
                  style={styles.primaryButton}
                  tint={defaultStyles.green}
                  textStyle={{ fontSize: defaultStyles.fontSize[0] }}
                  disabled={game.team1.length + game.team2.length < 4}
                  onPress={() => {
                    connection.start();
                  }}
                />
              </View>
            </>
          )}
          {serverMessage && (
            <View
              style={[
                {
                  backgroundColor: defaultStyles.red,
                },
                styles.instructionsContainer,
              ]}
            >
              <CreteRoundText
                white={true}
                style={{ fontSize: defaultStyles.fontSize[0] }}
              >
                {i18n.t(serverMessage.message)}
              </CreteRoundText>
            </View>
          )}
        </ScrollView>
      </SafeAreaView>
      {roomScorePopup && (
        <Popup
          title={
            weWon()
              ? i18n.t('end-game-title-win')
              : i18n.t('end-game-title-lose')
          }
          description={
            weWon()
              ? i18n.t('end-game-description-win')
              : i18n.t('end-game-description-lose')
          }
          hidePopup={() => {
            setRoomScorePopup(false);
            setLastGameResults(undefined);
          }}
          options={
            showDonateCTA
              ? [
                  { value: i18n.t('close'), color: defaultStyles.red },
                  {
                    value: i18n.t('donate'),
                    color: defaultStyles.green,
                    action: () =>
                      Linking.openURL(
                        `https://app.bela.fun/donate-${Platform.OS}-endgame`
                      ),
                  },
                ]
              : [
                  {
                    value: i18n.t('close'),
                  },
                ]
          }
        >
          {game && lastGameResults && (
            <>
              <View style={styles.roundScoreContainer}>
                <View style={styles.roundScoreContainerColumn}>
                  <CreteRoundText
                    primary
                    style={{
                      fontSize: defaultStyles.fontSize[1],
                      opacity: 0.6,
                    }}
                  >
                    {i18n.t('we')}
                  </CreteRoundText>
                  <CreteRoundText
                    primary
                    style={{
                      fontSize: defaultStyles.fontSize[2],
                    }}
                  >
                    {game.myTeamId === 1
                      ? lastGameResults.team1
                      : lastGameResults.team2}
                  </CreteRoundText>
                </View>

                <MaterialCommunityIcons
                  name='cards-playing-outline'
                  size={30}
                  color={defaultStyles.primary}
                  style={{ opacity: 0.6 }}
                />

                <View style={styles.roundScoreContainerColumn}>
                  <CreteRoundText
                    primary
                    style={{
                      fontSize: defaultStyles.fontSize[1],
                      opacity: 0.6,
                    }}
                  >
                    {i18n.t('you')}
                  </CreteRoundText>
                  <CreteRoundText
                    style={{
                      fontSize: defaultStyles.fontSize[2],
                    }}
                  >
                    {game.myTeamId === 1
                      ? lastGameResults.team2
                      : lastGameResults.team1}
                  </CreteRoundText>
                </View>
              </View>
              {showDonateCTA && (
                <CreteRoundText
                  italic
                  primary
                  style={{
                    opacity: 0.7,
                    marginVertical: defaultStyles.margin[4],
                    alignSelf: 'flex-start',
                  }}
                >
                  {i18n.t('donate-description')}
                </CreteRoundText>
              )}
            </>
          )}
        </Popup>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',

    backgroundColor: defaultStyles.almostWhite,
  },
  roomNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    maxWidth: 500,
    marginBottom: defaultStyles.margin[3],
  },
  roomNameLabel: {
    fontSize: defaultStyles.fontSize[0],
    color: defaultStyles.primaryLight,
  },
  roomName: {
    fontSize: defaultStyles.fontSize[2],
  },
  roomPassword: {
    fontSize: defaultStyles.fontSize[0],
    color: defaultStyles.primaryLight,
    marginTop: defaultStyles.margin[0],
  },
  roomPrivacyContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  roomPrivacyLabel: {
    marginBottom: defaultStyles.margin[0],
  },
  playerCard: {
    marginVertical: defaultStyles.margin[0],
    width: '90%',
    maxWidth: 500,
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '80%',
    maxWidth: 450,
    marginTop: defaultStyles.margin[2],
  },
  primaryButton: {},
  secondaryButton: {
    paddingVertical: defaultStyles.padding[4],
  },
  instructionsContainer: {
    position: 'absolute',
    top: 10,
    paddingVertical: defaultStyles.padding[0],
    paddingHorizontal: defaultStyles.padding[2],
    borderRadius: 6,
  },
  roundScoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    borderRadius: 6,
    paddingVertical: defaultStyles.padding[4],
    marginTop: defaultStyles.margin[1],
    marginBottom: defaultStyles.margin[4],
    backgroundColor: defaultStyles.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.16,
    shadowRadius: 10,

    elevation: 12,
  },
  roundScoreContainerColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default RoomScreen;
