import React from 'react';
import { Text } from 'react-native';
import * as defaultStyles from '../../../assets/defaultStyles';

const CreteRoundText = (props) => {
  const fontWeight = () => {
    if (props.italic) return { fontFamily: 'crete-round-italic' };
    return { fontFamily: 'crete-round-regular' };
  };

  const color = () => {
    if (props.white) return { color: defaultStyles.white };
    if (props.red) return { color: defaultStyles.red };
    if (props.green) return { color: defaultStyles.green };
    if (props.primary) return { color: defaultStyles.primary };
    return {};
  };

  return (
    <Text {...props} style={[fontWeight(), color(), props.style]}>
      {props.children}
    </Text>
  );
};

export default CreteRoundText;
