import React, { useContext, useEffect, useState } from 'react';
import {
  Dimensions,
  Platform,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import * as defaultStyles from '../../../assets/defaultStyles';
import { modernSuits } from '../../../assets/suits';
import Card from '../../components/Card';
import DealerChip from '../../components/DealerChip';
import Popup from '../../components/Popup';
import Suit from '../../components/Suit';
import TemporaryPopup from '../../components/TemporaryPopup';
import CreteRoundText from '../../components/text/CreteRoundText';
import {
  GameContext,
  SettingsContext,
  UserContext,
} from '../../DefaultContainer';
import usePrevious from '../../hooks/usePrevious';
import useTimer from '../../hooks/useTimer';
import { http } from '../../http';
import CardTable from './CardTable';
import Declarations from './Declarations';
import gameStateConstants from './gameStateConstants';
import Reactions from './Reactions/Reactions';
import ScoreBar from './ScoreBar';
import TimeForMove from './TimeForMove';
import * as Haptics from 'expo-haptics';

const GameScreen = () => {
  const { game, serverMessage, connection, destroyConnection } = useContext(
    GameContext
  );
  const { i18n, settings } = useContext(SettingsContext);
  const { user } = useContext(UserContext);

  const [wantToDeclare, setWantToDeclare] = useState(true);
  const [currentGameState, setCurrentGameState] = useState(null);
  const [leaveRoomPopup, setLeaveRoomPopup] = useState(false);
  const [belaPopup, setBelaPopup] = useState({ show: false, card: '' });
  const [declarationsPopup, setDeclarationsPopup] = useState(false);
  const [automodePopup, setAutomodePopup] = useState({
    show: false,
    lastHidden: null,
  });
  const [roundScorePopup, setRoundScorePopup] = useState(false);
  const [matchScorePopup, setMatchScorePopup] = useState(false);
  const [tableCards, setTableCards] = useState({
    south: null,
    east: null,
    west: null,
    north: null,
  });

  const prevGameState = usePrevious(currentGameState);

  const initializeTableCards = () => {
    setTableCards({
      south: null,
      east: null,
      west: null,
      north: null,
    });
  };

  const isMyTurn = () => {
    if (game.currentPlayer) {
      return game.currentPlayer.id === game.me.id;
    }
  };

  const handContains = (value) => {
    let contains = false;
    game.me.hand.map((card) => {
      if (card.str === value && card.thrown === false) {
        contains = true;
      }
    });
    return contains;
  };

  const getPoints = (my) => {
    if (game && game.points) {
      if (my)
        return game.myTeamId === 1 ? game.points.team1 : game.points.team2;
      else return game.myTeamId === 1 ? game.points.team2 : game.points.team1;
    }
  };

  const declare = () => {
    if (wantToDeclare) {
      connection.sendAllDeclaration(game.me.hand);
    } else {
      connection.sendDeclaration([]);
    }
  };

  const currentPlayerPosition = () => {
    if (game && game.match && game.currentPlayer) {
      if (game.currentPlayer.id === game.leftOpponent.id) return 3;
      if (game.currentPlayer.id === game.teammate.id) return 2;
      if (game.currentPlayer.id === game.rightOpponent.id) return 1;
      if (isMyTurn()) return 0;
    }
  };

  const currentGameStatus = () => {
    if (game && game.match && game.currentPlayer) {
      if (currentGameState === gameStateConstants.TAKING) {
        if (isMyTurn()) {
          if (game.currentDealerId === game.currentPlayer.id) {
            return i18n.t('status-set-trump-must');
          } else {
            return i18n.t('status-set-trump');
          }
        }
        return game.currentPlayer.name + ' ' + i18n.t('status-deciding');
      }
      if (currentGameState === gameStateConstants.DECLARING) {
        return i18n.t('status-declare');
      }
      if (currentGameState === gameStateConstants.THROWING) {
        return isMyTurn()
          ? i18n.t('status-your-turn')
          : game.currentPlayer.name + i18n.t('status-turn');
      }
    }
  };

  const getGameStatusBackgroundColor = () => {
    if (serverMessage) {
      return defaultStyles.red;
    }
    return isMyTurn() || game.gameState === gameStateConstants.DECLARING
      ? defaultStyles.green
      : 'transparent';
  };

  const isGameStatusWhite =
    serverMessage ||
    isMyTurn() ||
    game.gameState === gameStateConstants.DECLARING;
  const gameStatusBackgroundColor = getGameStatusBackgroundColor();

  useEffect(() => {
    if (currentGameState === gameStateConstants.DECLARING) {
      declare();
    }
    if (currentGameState === gameStateConstants.TAKING) {
      initializeTableCards();
    }
  }, [wantToDeclare, currentGameState]);

  useEffect(() => {
    if (
      prevGameState === gameStateConstants.DECLARING &&
      currentGameState === gameStateConstants.THROWING
    ) {
      setDeclarationsPopup(true);
    }
  }, [currentGameState]);

  useEffect(() => {
    if (
      automodePopup.lastHidden &&
      Date.now() - automodePopup.lastHidden < 5000
    ) {
      return;
    }
    if (game.me.isInAutoMode) {
      setAutomodePopup({ show: true, lastHidden: null });
    }
  }, [game.me]);

  const dismissAutoModePopup = () => {
    setAutomodePopup({ show: false, lastHidden: Date.now() });
    connection.sendReadyToPlay();
  };

  useEffect(() => {
    setCurrentGameState(game.gameState);

    if (game.gameState === gameStateConstants.THROWING) {
      if (game.tableCards.length === 0) {
        initializeTableCards();
      }
      game.tableCards.forEach((card) => {
        switch (card.player.id) {
          case game.me.id:
            setTableCards({
              ...tableCards,
              south: settings.cards[card.card.str],
            });
            break;
          case game.teammate.id:
            setTableCards({
              ...tableCards,
              north: settings.cards[card.card.str],
            });
            break;
          case game.leftOpponent.id:
            setTableCards({
              ...tableCards,
              west: settings.cards[card.card.str],
            });
            break;
          case game.rightOpponent.id:
            setTableCards({
              ...tableCards,
              east: settings.cards[card.card.str],
            });
            break;
        }
      });
    }
  }, [game]);

  useEffect(() => {
    if (
      prevGameState === gameStateConstants.COUNTING &&
      currentGameState === gameStateConstants.TAKING
    ) {
      if (game.previousGamePoints) {
        setMatchScorePopup(true);
      } else {
        if (
          game.previousRoundPoints &&
          typeof game.previousRoundPoints.team1 !== 'undefined'
        ) {
          setRoundScorePopup(true);
        }
      }
    }
  }, [currentGameState]);

  const { timeLeft } = useTimer(game);

  if (game && game.match && game.match.finished) {
    return (
      <SafeAreaView style={styles.mainContainer}>
        <CreteRoundText>
          Match has finished, please proceed to the nearest exit!
        </CreteRoundText>
        <TouchableOpacity
          style={styles.leaveRoomIcon}
          onPress={() => setLeaveRoomPopup(true)}
        >
          <Ionicons name='ios-log-out' size={30} />
        </TouchableOpacity>
        {leaveRoomPopup && (
          <Popup
            title={i18n.t('leave-game-title')}
            description={i18n.t('leave-game-description')}
            hidePopup={() => {
              setLeaveRoomPopup(false);
            }}
            options={[
              {
                value: i18n.t('no'),
                action: () => {
                  setLeaveRoomPopup(false);
                },
              },
              {
                value: i18n.t('yes'),
                action: async () => {
                  try {
                    await http.post(`/leaveRoom?hash=${game.roomId}`, {
                      id: user.id,
                    });
                    destroyConnection();
                  } catch (e) {
                    console.warn(e);
                  }
                },
              },
            ]}
          />
        )}
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.mainContainer}>
      {game && game.match && (
        <ScoreBar
          leftPoints={getPoints(true)}
          rightPoints={getPoints(false)}
          leftSum={
            game && game.gamePoints && game.myTeamId === 1
              ? game.gamePoints.team1
              : game.gamePoints.team2
          }
          rightSum={
            game && game.gamePoints && game.myTeamId === 1
              ? game.gamePoints.team2
              : game.gamePoints.team1
          }
          trump={game.currentTrump}
          style={styles.scoreBar}
          onPress={() => setDeclarationsPopup(true)}
          leaveAction={() => setLeaveRoomPopup(true)}
        >
          {game.timeIsUsed &&
            currentGameState !== gameStateConstants.DECLARING && (
              <TimeForMove
                timeLeft={timeLeft}
                totalTime={game.timeForMove}
                vibrate={isMyTurn() && !game.me.isInAutoMode}
              />
            )}
        </ScoreBar>
      )}
      {tableCards && game.match && (
        <CardTable
          south={game.tableCardsMap.south}
          north={game.tableCardsMap.north}
          east={game.tableCardsMap.east}
          west={game.tableCardsMap.west}
          game={game}
          leaveAction={() => setLeaveRoomPopup(true)}
        />
      )}

      <Animatable.View
        animation={serverMessage ? 'pulse' : isMyTurn() ? 'tada' : ''}
        iterationDelay={serverMessage ? 0 : 3000}
        easing='ease-out'
        iterationCount='infinite'
        style={[
          {
            backgroundColor: gameStatusBackgroundColor,
          },
          styles.instructionsContainer,
        ]}
      >
        <CreteRoundText
          style={{
            fontSize: defaultStyles.fontSize[0],
            color: isGameStatusWhite
              ? defaultStyles.white
              : defaultStyles.black,
          }}
        >
          {serverMessage ? i18n.t(serverMessage.message) : currentGameStatus()}
        </CreteRoundText>
      </Animatable.View>

      <View
        style={[
          styles.cardsContainer,
          {
            borderRadius: 20,
            paddingVertical: defaultStyles.padding[0],
          },
        ]}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: useWindowDimensions().width < 700 ? 'column' : 'row',
          }}
        >
          {game.currentDealerId === game.me.id && (
            <DealerChip
              style={{
                position: 'absolute',
                zIndex: 999,
                top: -15,
                right: -15,
              }}
              scale={1.2}
            />
          )}
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            {game.me.hand.slice(0, 4).map((card, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  style={styles.cardButton}
                  disabled={
                    card.thrown ||
                    !isMyTurn() ||
                    game.gameState !== gameStateConstants.THROWING
                  }
                  onPress={() => {
                    if (Platform.OS === 'ios' && settings.haptics)
                      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                    if (card.str.startsWith(game.currentTrump.suit)) {
                      if (
                        (card.str.endsWith('Q') &&
                          handContains(game.currentTrump.suit + '_K')) ||
                        (card.str.endsWith('K') &&
                          handContains(game.currentTrump.suit + '_Q'))
                      ) {
                        setBelaPopup({ show: true, card: card.str });
                      } else {
                        connection.cardPressed(
                          { cardStr: card.str, bela: false },
                          game.gameState
                        );
                      }
                    } else {
                      connection.cardPressed(
                        { cardStr: card.str, bela: false },
                        game.gameState
                      );
                    }
                  }}
                >
                  <Card
                    scale={Dimensions.get('window').width < 400 ? 1.2 : 1.6}
                    hidden={card.thrown}
                    card={
                      card.str ? settings.cards[card.str] : settings.cards.back
                    }
                  />
                </TouchableOpacity>
              );
            })}
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            {game.me.hand.slice(4).map((card, index) => {
              return (
                <TouchableOpacity
                  key={index + 4}
                  style={styles.cardButton}
                  disabled={
                    card.thrown ||
                    !isMyTurn() ||
                    game.gameState !== gameStateConstants.THROWING
                  }
                  onPress={() => {
                    if (Platform.OS === 'ios' && settings.haptics)
                      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                    if (card.str.startsWith(game.currentTrump.suit)) {
                      if (
                        (card.str.endsWith('Q') &&
                          handContains(game.currentTrump.suit + '_K')) ||
                        (card.str.endsWith('K') &&
                          handContains(game.currentTrump.suit + '_Q'))
                      ) {
                        setBelaPopup({ show: true, card: card.str });
                      } else {
                        connection.cardPressed(
                          { cardStr: card.str, bela: false },
                          game.gameState
                        );
                      }
                    } else {
                      connection.cardPressed(
                        { cardStr: card.str, bela: false },
                        game.gameState
                      );
                    }
                  }}
                >
                  <Card
                    scale={Dimensions.get('window').width < 400 ? 1.2 : 1.6}
                    hidden={card.thrown}
                    card={
                      settings.cards[card.str]
                        ? settings.cards[card.str]
                        : settings.cards.back
                    }
                  />
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
      </View>

      <View style={styles.actionBarContainer}>
        {game.gameState === gameStateConstants.TAKING && (
          <View style={styles.takingContainer}>
            {Object.keys(modernSuits).map((suit) => {
              return (
                <TouchableOpacity
                  key={suit}
                  disabled={!isMyTurn()}
                  style={{ opacity: isMyTurn() ? 1 : 0.5 }}
                  onPress={() => connection.setTrump(suit)}
                >
                  <Suit scale={0.35} suit={modernSuits[suit]} />
                </TouchableOpacity>
              );
            })}
            <TouchableOpacity
              onPress={() => {
                connection.passTrump();
              }}
              disabled={!isMyTurn() || game.currentDealerId === game.me.id}
              style={{
                opacity: !isMyTurn() ? 0.5 : 1,
                display: game.currentDealerId === game.me.id ? 'none' : 'flex',
              }}
            >
              <CreteRoundText style={{ fontSize: defaultStyles.fontSize[1] }}>
                {i18n.t('next')}
              </CreteRoundText>
            </TouchableOpacity>
          </View>
        )}
        {game.gameState === gameStateConstants.DECLARING && (
          <View style={styles.declaringContainer}>
            <CreteRoundText style={styles.declaringLabel}>
              {i18n.t('want-to-declare')}
            </CreteRoundText>
            <View style={styles.declarationsAnswersContainer}>
              <TouchableOpacity
                onPress={() => setWantToDeclare(false)}
                style={[
                  {
                    backgroundColor: wantToDeclare
                      ? 'transparent'
                      : defaultStyles.red,
                  },
                  styles.declarationsAnswer,
                ]}
              >
                <CreteRoundText
                  style={{
                    color: wantToDeclare
                      ? defaultStyles.primary
                      : defaultStyles.white,
                    fontSize: defaultStyles.fontSize[1],
                  }}
                >
                  {i18n.t('no')}
                </CreteRoundText>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setWantToDeclare(true)}
                style={[
                  {
                    backgroundColor: wantToDeclare
                      ? defaultStyles.green
                      : 'transparent',
                  },
                  styles.declarationsAnswer,
                ]}
              >
                <CreteRoundText
                  style={{
                    color: wantToDeclare
                      ? defaultStyles.white
                      : defaultStyles.primary,
                    fontSize: defaultStyles.fontSize[1],
                  }}
                >
                  {i18n.t('yes')}
                </CreteRoundText>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {game.gameState === gameStateConstants.THROWING && (
          <View style={styles.reactionsContainer}>
            {/* {['😍', '👍', '🔥', '🥳', '😡', '😢'].map((emoji) => {
              return <ReactionButton key={emoji} emoji={emoji} />;
            })} */}
            <Reactions />
          </View>
        )}
      </View>

      {leaveRoomPopup && (
        <Popup
          title={i18n.t('leave-game-title')}
          description={i18n.t('leave-game-description')}
          hidePopup={() => {
            setLeaveRoomPopup(false);
          }}
          options={[
            {
              value: i18n.t('no'),
            },
            {
              value: i18n.t('yes'),
              action: async () => {
                try {
                  await http.post(`/leaveRoom?hash=${game.roomId}`, {
                    id: user.id,
                  });
                  destroyConnection();
                } catch (e) {
                  console.warn(e);
                }
              },
            },
          ]}
        />
      )}
      {belaPopup.show && (
        <Popup
          title={i18n.t('declare-bela-title')}
          description={i18n.t('declare-bela-description')}
          hidePopup={() => {
            setBelaPopup({ ...belaPopup, show: false });
          }}
          options={[
            {
              value: i18n.t('no'),
              action: () => {
                connection.cardPressed(
                  { cardStr: belaPopup.card, bela: false },
                  game.gameState
                );
                setBelaPopup({ show: false, card: '' });
              },
            },
            {
              value: i18n.t('yes'),
              action: () => {
                connection.cardPressed(
                  { cardStr: belaPopup.card, bela: true },
                  game.gameState
                );
                setBelaPopup({ show: false, card: '' });
              },
            },
          ]}
        />
      )}
      {game && game.me.isInAutoMode && automodePopup.show && (
        <Popup
          title={i18n.t('you-were-inactive')}
          description={i18n.t('automode-explained')}
          hidePopup={dismissAutoModePopup}
          options={[
            {
              value: i18n.t('back-to-game'),
            },
          ]}
        ></Popup>
      )}
      {game && game.points && declarationsPopup && (
        <TemporaryPopup
          title={i18n.t('declarations')}
          hidePopup={() => {
            setDeclarationsPopup(false);
          }}
        >
          {game &&
          game.points.team1.declarations.length === 0 &&
          game.points.team2.declarations.length === 0 ? (
            <CreteRoundText
              white
              style={{ fontSize: defaultStyles.fontSize[1] }}
            >
              {i18n.t('no-declarations')}
            </CreteRoundText>
          ) : (
            <View style={styles.declarationsContainer}>
              <Declarations declarations={game.points.team1.declarations} />
              <Declarations declarations={game.points.team2.declarations} />
            </View>
          )}
        </TemporaryPopup>
      )}
      {roundScorePopup && (
        <TemporaryPopup
          title={i18n.t('round-score-title')}
          hidePopup={() => {
            setRoundScorePopup(false);
          }}
        >
          {game && game.previousRoundPoints && (
            <View style={styles.roundScoreContainer}>
              {
                <CreteRoundText
                  white
                  style={{
                    fontSize: defaultStyles.fontSize[2],
                    alignSelf: 'flex-start',
                  }}
                >
                  {i18n.t('we')}
                </CreteRoundText>
              }
              {
                <CreteRoundText
                  white
                  style={{
                    fontSize: defaultStyles.fontSize[2],
                    alignSelf: 'flex-start',
                  }}
                >
                  {game.myTeamId === 1
                    ? game.previousRoundPoints.team1
                    : game.previousRoundPoints.team2}
                </CreteRoundText>
              }
              {
                <CreteRoundText
                  white
                  style={{
                    fontSize: defaultStyles.fontSize[2],
                    alignSelf: 'flex-start',
                  }}
                >
                  {i18n.t('you')}
                </CreteRoundText>
              }
              {
                <CreteRoundText
                  white
                  style={{
                    fontSize: defaultStyles.fontSize[2],
                    alignSelf: 'flex-start',
                  }}
                >
                  {game.myTeamId === 1
                    ? game.previousRoundPoints.team2
                    : game.previousRoundPoints.team1}
                </CreteRoundText>
              }
            </View>
          )}
        </TemporaryPopup>
      )}
      {matchScorePopup && (
        <TemporaryPopup
          title={i18n.t('game-finished')}
          hidePopup={() => {
            setMatchScorePopup(false);
          }}
        >
          {game && game.previousGamePoints && (
            <View style={styles.roundScoreContainer}>
              {
                <CreteRoundText
                  white
                  style={{
                    fontSize: defaultStyles.fontSize[2],
                    alignSelf: 'flex-start',
                  }}
                >
                  {game.myTeamId === 1 ? i18n.t('we') : i18n.t('you')}
                </CreteRoundText>
              }
              {
                <CreteRoundText
                  white
                  style={{
                    fontSize: defaultStyles.fontSize[2],
                    alignSelf: 'flex-start',
                  }}
                >
                  {game.myTeamId === 1
                    ? game.previousGamePoints.team1
                    : game.previousGamePoints.team2}
                </CreteRoundText>
              }
              {
                <CreteRoundText
                  white
                  style={{
                    fontSize: defaultStyles.fontSize[2],
                    alignSelf: 'flex-start',
                  }}
                >
                  {game.myTeamId === 1 ? i18n.t('you') : i18n.t('we')}
                </CreteRoundText>
              }
              {
                <CreteRoundText
                  white
                  style={{
                    fontSize: defaultStyles.fontSize[2],
                    alignSelf: 'flex-start',
                  }}
                >
                  {game.myTeamId === 1
                    ? game.previousGamePoints.team2
                    : game.previousGamePoints.team1}
                </CreteRoundText>
              }
            </View>
          )}
        </TemporaryPopup>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: defaultStyles.primary10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableStyle: {
    flexGrow: 1,
    width: '100%',
  },
  instructionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: defaultStyles.padding[0],
    paddingHorizontal: defaultStyles.padding[2],
    margin: defaultStyles.margin[0],
    marginBottom: defaultStyles.margin[1],
    borderRadius: 6,
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexShrink: 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: defaultStyles.green30,
    paddingVertical: defaultStyles.padding[0],
    paddingHorizontal: defaultStyles.padding[0],
  },
  actionBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: 80,
  },
  cardButton: {
    padding: defaultStyles.padding[0],
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: Platform.OS === 'web' ? 0 : 0.1,
    shadowRadius: 10,

    elevation: 12,
  },
  takingContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexShrink: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    paddingVertical: defaultStyles.padding[4],
    paddingHorizontal: defaultStyles.padding[1],
    maxWidth: 600,
  },
  declaringContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: defaultStyles.padding[3],
    paddingHorizontal: defaultStyles.padding[3],
    width: '100%',
    maxWidth: 600,
  },
  declaringLabel: {
    flexShrink: 1,
    opacity: 0.5,
    fontSize: defaultStyles.fontSize[0],
  },
  declarationsAnswersContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  declarationsAnswer: {
    borderRadius: 3,
    paddingVertical: defaultStyles.padding[2],
    paddingHorizontal: defaultStyles.padding[3],
    marginVertical: defaultStyles.margin[0],
    marginHorizontal: defaultStyles.margin[0],
  },
  reactionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    maxWidth: 600,
    paddingVertical: defaultStyles.padding[3],
    paddingHorizontal: defaultStyles.padding[3],
  },
  gamePointsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  currentPoints: {
    fontSize: defaultStyles.fontSize[2],
  },
  gamePoints: {
    fontSize: defaultStyles.fontSize[0],
  },
  trumpContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  declarationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: defaultStyles.margin[2],
  },
  roundScoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: defaultStyles.margin[2],
  },
  scoreBar: {},
  leaveRoomIcon: {
    padding: defaultStyles.padding[0],
  },
});

export default GameScreen;
