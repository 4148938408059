import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import * as defaultStyles from '../../../../assets/defaultStyles';
import CreteRoundText from '../../../components/text/CreteRoundText';

const ReactionButton = (props) => {
  const { emoji } = props;

  return (
    <TouchableOpacity style={styles.container} {...props}>
      <CreteRoundText style={styles.emojiText}>{emoji}</CreteRoundText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  emojiText: {
    fontSize: defaultStyles.fontSize[3],
  },
});

export default ReactionButton;
