import React, { useContext, useState } from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';
import * as defaultStyles from '../../../../assets/defaultStyles';
import CreteRoundText from '../../../components/text/CreteRoundText';
import { GameContext, SettingsContext } from '../../../DefaultContainer';
import ReactionButton from './ReactionButton';

const Reactions = () => {
  const { i18n } = useContext(SettingsContext);
  const { connection } = useContext(GameContext);

  const [messageAnim] = useState(new Animated.Value(0));
  const [reactionAnim] = useState(new Animated.Value(1));

  const [myMessage, setMyMessage] = useState('');
  const [displayMyMessage, setDisplayMyMessage] = useState(false);

  const reactions = i18n.t('reactions');

  const getReactions = () => {
    return Object.keys(reactions).filter((key) => key !== 'bela');
  };

  const startAnimation = () => {
    setDisplayMyMessage(true);
    Animated.timing(messageAnim, {
      toValue: 1,
      duration: 300,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: true,
    }).start(() => {
      Animated.timing(messageAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.inOut(Easing.ease),
        delay: 2500,
        useNativeDriver: true,
      }).start(() => {
        setDisplayMyMessage(false);
      });
    });

    Animated.timing(reactionAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      Animated.timing(reactionAnim, {
        toValue: 1,
        duration: 300,
        delay: 2500,
        useNativeDriver: true,
      }).start();
    });
  };

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          {
            opacity: reactionAnim,
          },
          styles.buttons,
        ]}
      >
        {getReactions().map((key) => {
          return (
            <ReactionButton
              key={key}
              emoji={reactions[key].emoji}
              onPress={() => {
                setMyMessage(`${reactions[key].text} ${reactions[key].emoji}`);
                startAnimation();
                connection.sendReaction(key);
              }}
              disabled={displayMyMessage}
            />
          );
        })}
      </Animated.View>

      {displayMyMessage && (
        <Animated.View
          style={[
            {
              opacity: messageAnim,
              transform: [
                {
                  translateY: messageAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [20, 0],
                  }),
                },
              ],
            },
            styles.myMessage,
          ]}
        >
          <CreteRoundText primary style={styles.myMessageText}>
            {myMessage}
          </CreteRoundText>
        </Animated.View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 20,
    paddingHorizontal: defaultStyles.padding[2],
  },
  buttons: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  myMessage: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: defaultStyles.white,
    paddingHorizontal: defaultStyles.padding[2],
    paddingVertical: defaultStyles.padding[0],
    borderRadius: 10,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,

    elevation: 12,
  },
  myMessageText: {
    fontSize: defaultStyles.fontSize[1],
  },
});

export default Reactions;
