import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import * as defaultStyles from '../../../assets/defaultStyles';
import { modernSuits } from '../../../assets/suits';
import Suit from '../../components/Suit';
import ArchivoText from '../../components/text/ArchivoText';
import CreteRoundText from '../../components/text/CreteRoundText';
import { SettingsContext } from '../../DefaultContainer';

const ScoreBar = (props) => {
  const { i18n } = useContext(SettingsContext);

  const [leftDeclarationSum, setLeftDeclarationSum] = useState(0);
  const [rightDeclarationSum, setRightDeclarationSum] = useState(0);

  const {
    leftPoints,
    rightPoints,
    leftSum,
    rightSum,
    trump,
    onPress = () => {},
    children,
  } = props;

  useEffect(() => {
    let leftSum = 0;
    leftPoints.declarations.map((declaration) => {
      leftSum += declaration.declarationType;
    });
    leftSum += leftPoints.bela ? 20 : 0;
    setLeftDeclarationSum(leftSum);

    let rightSum = 0;
    rightPoints.declarations.map((declaration) => {
      rightSum += declaration.declarationType;
    });
    rightSum += rightPoints.bela ? 20 : 0;
    setRightDeclarationSum(rightSum);
  }, [leftPoints.declarations, rightPoints.declarations]);

  return (
    <TouchableOpacity onPress={onPress} style={[props.style, styles.container]}>
      <View style={styles.pointsContainer}>
        <CreteRoundText style={styles.pointsTitle}>
          {i18n.t('we')}
        </CreteRoundText>
        <View style={styles.currentPointsContainer}>
          <ArchivoText bold style={styles.currentPoints}>
            {leftPoints.points}
          </ArchivoText>
          {leftDeclarationSum !== 0 && (
            <ArchivoText style={styles.declarationPoints}>
              +{leftDeclarationSum}
            </ArchivoText>
          )}
        </View>
        <ArchivoText style={styles.gamePoints}>{leftSum}</ArchivoText>
      </View>
      {trump && (
        <Animatable.View animation='rubberBand' style={styles.trumpContainer}>
          <View style={styles.suitContainer}>
            <Suit scale={0.3} suit={modernSuits[trump.suit]} />
          </View>
          <CreteRoundText primary>{trump.player.name}</CreteRoundText>
        </Animatable.View>
      )}
      <View style={styles.pointsContainer}>
        <CreteRoundText style={styles.pointsTitle}>
          {i18n.t('you')}
        </CreteRoundText>
        <View style={styles.currentPointsContainer}>
          <ArchivoText bold style={styles.currentPoints}>
            {rightPoints.points}
          </ArchivoText>
          {rightDeclarationSum !== 0 && (
            <ArchivoText style={styles.declarationPoints}>
              +{rightDeclarationSum}
            </ArchivoText>
          )}
        </View>
        <ArchivoText style={styles.gamePoints}>{rightSum}</ArchivoText>
      </View>
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',

    backgroundColor: defaultStyles.white,
    width: '90%',
    maxWidth: 600,
    margin: defaultStyles.margin[1],
    padding: defaultStyles.padding[1],
    borderRadius: 14,
  },
  pointsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
  },
  trumpContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  suitContainer: {
    backgroundColor: defaultStyles.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: defaultStyles.padding[2],
    borderRadius: 100,
  },
  pointsTitle: {
    fontSize: defaultStyles.fontSize[0],
    color: defaultStyles.primaryLight,
  },
  currentPoints: {
    fontSize: defaultStyles.fontSize[2],
    color: defaultStyles.primary,
    paddingHorizontal: defaultStyles.padding[0],
  },
  gamePoints: {
    fontSize: defaultStyles.fontSize[0],
    color: defaultStyles.primaryLight,
  },
  declarationPoints: {
    fontSize: defaultStyles.fontSize[0],
    color: defaultStyles.primary,
  },
  currentPointsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ScoreBar;
