import { useState, useEffect } from 'react';

export default function useTimer(game) {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    setTimeLeft(game.timeForMove - game.secondsPassedAfterLastAction);
  }, [game.currentPlayer]);

  useEffect(() => {
    const timer =
      timeLeft > 0 &&
      setTimeout(() => {
        const newValue = Math.max(timeLeft - 1, 0);
        setTimeLeft(newValue);
      }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  return { timeLeft };
}
