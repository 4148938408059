export default {
  sad: {
    emoji: '😢',
    text: 'Ajooj',
  },
  awesome: {
    emoji: '🔥',
    text: 'TOO!',
  },
  angry: {
    emoji: '😡',
    text: 'Grrr!',
  },
  celebrate: {
    emoji: '🎉',
    text: 'Bravo!',
  },
  oops: {
    emoji: '🙃',
    text: 'Ups',
  },
  mock: {
    emoji: '😜',
    text: 'Nemreš protiv zvanja',
  },
  bela: {
    emoji: '🤘',
    text: 'Bela!',
  },
};
