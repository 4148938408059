import { Feather, Ionicons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import * as defaultStyles from '../../assets/defaultStyles';
import { SettingsContext, UserContext } from '../DefaultContainer';
import i18n from '../localization';
import ArchivoText from './text/ArchivoText';
import CreteRoundText from './text/CreteRoundText';

const TopBar = (props) => {
  const { navigation, logout, donateEmphasis, donatePress } = props;

  const { initializeUser } = React.useContext(UserContext);
  const { i18n } = React.useContext(SettingsContext);

  return (
    <View style={styles.topBar}>
      {props.children}
      {logout && (
        <TouchableOpacity
          style={styles.topBarButton}
          onPress={() => initializeUser()}
        >
          <Feather name='log-out' size={30} color={defaultStyles.primary} />
        </TouchableOpacity>
      )}
      {donatePress && (
        <TouchableOpacity
          style={styles.topBarButton}
          onPress={() => donatePress()}
        >
          {donateEmphasis ? (
            <View
              style={{
                backgroundColor: defaultStyles.green,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingHorizontal: defaultStyles.padding[2],
                paddingVertical: defaultStyles.padding[0],
                borderRadius: 4,
              }}
            >
              <Feather
                name='heart'
                size={defaultStyles.fontSize[0]}
                color={defaultStyles.white}
              />
              <CreteRoundText
                style={{ fontSize: defaultStyles.fontSize[0] }}
                white
              >
                {'  ' + i18n.t('donate')}
              </CreteRoundText>
            </View>
          ) : (
            <Feather name='heart' size={20} color={defaultStyles.green} />
          )}
        </TouchableOpacity>
      )}
      <TouchableOpacity
        style={styles.topBarButton}
        onPress={() => navigation.toggleDrawer()}
      >
        <Feather name='menu' size={30} color={defaultStyles.primary} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  topBar: {
    width: '100%',
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: defaultStyles.padding[2],
  },
  topBarButton: {
    padding: defaultStyles.padding[0],
    marginHorizontal: defaultStyles.margin[0],
  },
});

export default TopBar;
