import React, { useContext, useEffect, useState } from 'react';
import { Animated, Dimensions, Easing, StyleSheet, View } from 'react-native';
import avatars from '../../../../assets/avatars';
import * as defaultStyles from '../../../../assets/defaultStyles';
import Avatar from '../../../components/Avatar';
import DealerChip from '../../../components/DealerChip';
import CreteRoundText from '../../../components/text/CreteRoundText';
import { GameContext, SettingsContext } from '../../../DefaultContainer';
import MessageBubble from './MessageBubble';

const calculateMessageBubblePosition = (playerPosition) => {
  // For left player
  if (playerPosition === 'left') return 'top-right';
  // For right player
  if (playerPosition === 'right') return 'top-left';
  // For top player
  return 'middle-right';
};

const Player = (props) => {
  const { player, active, position, width = 100, height = 100 } = props;

  const screenWidth = Dimensions.get('screen').width;
  const screenHeight = Dimensions.get('screen').height;

  const { reactions, removeReactionFromPlayer, game } = useContext(GameContext);
  const { i18n } = useContext(SettingsContext);

  const message =
    reactions && reactions[player.id]
      ? i18n.t('reactions')[reactions[player.id].reactionName]
      : '';

  const [messageAnim] = useState(new Animated.Value(0));
  const [animationInProgress, setAnimationInProgress] = useState(false);
  const [avatarImage, setAvatarImage] = useState(null);

  useEffect(() => {
    setAvatarImage(avatars.find((avatar) => avatar.name === player.avatar));
  }, []);

  useEffect(() => {
    if (animationInProgress) return;
    if (!reactions || !reactions[player.id]) return;

    setAnimationInProgress(true);
    Animated.timing(messageAnim, {
      toValue: 1,
      duration: 300,
      easing: Easing.cubic,
      useNativeDriver: true,
    }).start(() => {
      Animated.timing(messageAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.inOut(Easing.ease),
        delay: 2000,
        useNativeDriver: true,
      }).start(() => {
        setAnimationInProgress(false);
      });
    });
  }, [reactions[player.id]]);

  const activeStyle = active && {
    backgroundColor: defaultStyles.green,
    borderRadius: 15,
  };
  const activeTextStyle = {
    color: active ? defaultStyles.white : defaultStyles.black,
  };
  const messageBubblePosition = calculateMessageBubblePosition(position);

  let positionStyles;
  if (position === 'right') {
    positionStyles = {
      right: 0,
      top: '50%',
      transform: [{ translateY: -(height / 2) }],
    };
  } else if (position === 'left') {
    positionStyles = {
      left: 0,
      top: '50%',
      transform: [{ translateY: -(height / 2) }],
    };
  } else {
    positionStyles = {
      left:
        Dimensions.get('screen').width < 450 && screenHeight / screenWidth < 1.8
          ? '50%'
          : '50%',
      top: -height / 1.5,
      transform: [{ translateX: -(width / 2) }],
    };
  }

  return (
    <View style={[styles.container, positionStyles, { width, height }]}>
      {message !== '' && (
        <MessageBubble
          style={{
            opacity: messageAnim,
            transform: [
              {
                translateY: messageAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [20, 0],
                }),
              },
            ],
          }}
          position={messageBubblePosition}
          message={`${message.text} ${message.emoji}`}
        />
      )}
      <View>
        {player.id === game.currentDealerId && (
          <DealerChip style={styles.dealerChip} />
        )}
        <View style={styles.avatarContainer}>
          {avatarImage && (
            <Avatar
              avatar={avatarImage}
              width={40}
              height={40}
              style={{
                backgroundColor: defaultStyles.white,
              }}
              isOnline={player.isOnline}
            />
          )}
        </View>
      </View>
      <View style={[styles.name, activeStyle]}>
        <CreteRoundText style={[activeTextStyle]}>{player.name}</CreteRoundText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // zIndex: 999,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    borderRadius: 15,
    paddingHorizontal: defaultStyles.padding[1],
    paddingVertical: 3,
    marginTop: defaultStyles.margin[0],
  },
  avatarContainer: {
    shadowColor: '#000',
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10.0,
    elevation: 6,

    borderRadius: 20,
    overflow: 'hidden',
    width: 40,
    height: 40,
  },
  dealerChip: {
    zIndex: 1,
    position: 'absolute',
    bottom: -4,
    right: -4,
  },
});

export default Player;
