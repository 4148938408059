import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import * as defaultStyles from '../../../assets/defaultStyles';
import { modernSuits } from '../../../assets/suits';
import Card from '../../components/Card';
import Suit from '../../components/Suit';
import ArchivoText from '../../components/text/ArchivoText';
import CreteRoundText from '../../components/text/CreteRoundText';
import { SettingsContext } from '../../DefaultContainer';

const FOUR_OF_A_KIND = 100;
const FOUR_OF_NINES = 150;
const FOUR_OF_JACKS = 200;

const DeclarationRow = ({ declaration }) => {
  const { i18n, settings } = useContext(SettingsContext);

  return (
    <View style={styles.mainContainer}>
      <ArchivoText
        white
        bold
        style={{
          fontSize: defaultStyles.fontSize[4],
          paddingRight: defaultStyles.padding[1],
        }}
      >
        {declaration.declarationType}
      </ArchivoText>
      {!declaration.suit &&
        [FOUR_OF_A_KIND, FOUR_OF_NINES, FOUR_OF_JACKS].indexOf(
          declaration.declarationType
        ) !== -1 && (
          <>
            <Card
              scale={0.9}
              card={settings.cards['H_' + declaration.value]}
              style={{
                marginHorizontal: defaultStyles.margin[0],
              }}
            />
            <Card
              scale={0.9}
              card={settings.cards['D_' + declaration.value]}
              style={{
                marginHorizontal: defaultStyles.margin[0],
              }}
            />
            <Card
              scale={0.9}
              card={settings.cards['S_' + declaration.value]}
              style={{
                marginHorizontal: defaultStyles.margin[0],
              }}
            />
            <Card
              scale={0.9}
              card={settings.cards['C_' + declaration.value]}
              style={{
                marginHorizontal: defaultStyles.margin[0],
              }}
            />
          </>
        )}
      {declaration.suit && declaration.value && (
        <>
          <CreteRoundText
            white
            style={{
              fontSize: defaultStyles.fontSize[2],
              paddingRight: defaultStyles.padding[1],
            }}
          >
            {i18n.t('in')}
          </CreteRoundText>
          <Suit scale={0.3} suit={modernSuits[declaration.suit]} />
          <CreteRoundText
            white
            style={{
              fontSize: defaultStyles.fontSize[2],
              paddingHorizontal: defaultStyles.padding[1],
            }}
          >
            {i18n.t('to')}
          </CreteRoundText>
          <Card
            scale={0.9}
            card={settings.cards[declaration.suit + '_' + declaration.value]}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingVertical: defaultStyles.padding[1],
  },
});

export default DeclarationRow;
